
#patient-info-summary {
  a.badge:hover {
    color: white;
  }
}

.extra-info-container {
  // Needed to get it aligned below the thumbnail properly
  // Extends the .row -5px margin, hence the weird -2. It's actually +3
  margin-left: -2px;
}
