/* open-sans-300italic - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/open-sans-v17-latin-ext-300italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('./fonts/open-sans-v17-latin-ext-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/open-sans-v17-latin-ext-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-300italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/open-sans-v17-latin-ext-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300 - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/open-sans-v17-latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('./fonts/open-sans-v17-latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/open-sans-v17-latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-300.woff') format('woff'), /* Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/open-sans-v17-latin-ext-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/open-sans-v17-latin-ext-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('./fonts/open-sans-v17-latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/open-sans-v17-latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/open-sans-v17-latin-ext-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/open-sans-v17-latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('./fonts/open-sans-v17-latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/open-sans-v17-latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/open-sans-v17-latin-ext-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600italic - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/open-sans-v17-latin-ext-600italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('./fonts/open-sans-v17-latin-ext-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/open-sans-v17-latin-ext-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-600italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/open-sans-v17-latin-ext-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/open-sans-v17-latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('./fonts/open-sans-v17-latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/open-sans-v17-latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-600.woff') format('woff'), /* Modern Browsers */
  url('./fonts/open-sans-v17-latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/open-sans-v17-latin-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
