
.input-group {
  input, input:focus {
    border-left: none;
  }

  input::placeholder {
    color: $medimo-50;
  }

  .input-group-prepend {
    .input-group-text {
      background-color: white;
      border-right: none;
      color: black;
    }

    i.medimo-icon {
      opacity: 0.5;
      top: 0;
    }

  }

  input.form-control {
    border: $input-border-width solid $input-border-color;
    color: black;
    //border-left: none;
    line-height: $line-height-base-px;
  }
}