
  @import "/variables";

  .borders {
    border-left: 1px solid $medimo-25;
    border-top: 1px solid $medimo-25;
  }
  .border_left {
    border-left: 1px solid $medimo-25;
  }
  .all_borders {
    border: 1px solid $medimo-25;
  }
  .highlighted {
    background: $medimo-10;
  }
