.badge {
  padding: 0.75em;
  font-family: $font-family-base;
  min-width: $font-size-base + 0.2;
}

.badge.badge-sm {
  padding: ($badge-padding-y * 0.5 + 0.1) ($badge-padding-x * 0.5 + 0.1) ($badge-padding-y * 0.5 + 0.1) ($badge-padding-x * 0.5 + 0.1);
  font-weight: normal;
  font-size: $small-font-size
}