
@import "/variables";

a.disabled:hover {
  text-decoration: none;
}

.medimo-datetime-picker {
  position:relative;
  .button-row a, .fa-circle-question {
    position: relative;
    top: 8px;
  }

  &.with-day-of-week {
    input[type=text] {
      font-size: 0.830rem; // Als we de dag ervoor tonen maken we iets ruimte
    }
  }

  svg.svg-inline--fa {
    color: $medimo;

    &.fa-calendar-days {
      position: absolute;
      color: $medimo-25;
      right: 12px;
      top: 8px;
    }
  }
}
