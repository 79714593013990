/*------------------------
	icons
-------------------------*/
@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

$icon-copy: unicode(ea02);
$icon-circle-c: unicode(ea03);
$icon-circle-o: unicode(ea04);
$icon-radio-checked: unicode(ea05);
$icon-radio-unchecked: unicode(ea06);
$icon-undo: unicode(ea07);
$icon-square-checked: unicode(ea08);
$icon-square-unchecked: unicode(ea09);
$icon-reply-all: unicode(ea0a);
$icon-warning: unicode(ea0b);
$icon-mortar: unicode(ea0e);
$icon-turn-lef-down: unicode(ea0f);
$icon-turn-down-right: unicode(ea10);
$icon-turn-left-up: unicode(ea11);
$icon-turn-down-left: unicode(ea12);
$icon-single-2-o: unicode(ea14);
$icon-stethoscope: unicode(ea15);
$icon-data-download-c: unicode(ea16);
$icon-data-download-o: unicode(ea18);
$icon-star-c: unicode(ea19);
$icon-star-o: unicode(ea1a);

.icon-copy::before {
  content: $icon-copy;
}

.icon-circle-c::before {
  content: $icon-circle-c;
}

.icon-circle-o::before {
  content: $icon-circle-o;
}

.icon-radio-checked::before {
  content: $icon-radio-checked;
}

.icon-radio-unchecked::before {
  content: $icon-radio-unchecked;
}

.icon-undo::before {
  content: $icon-undo;
}

.icon-square-checked::before {
  content: $icon-square-checked;
}

.icon-square-unchecked::before {
  content: $icon-square-unchecked;
}

.icon-reply-all::before {
  content: $icon-reply-all;
}

.icon-warning::before {
  content: $icon-warning;
}

.icon-mortar::before {
  content: $icon-mortar;
}

.icon-turn-lef-down::before {
  content: $icon-turn-lef-down;
}

.icon-turn-down-right::before {
  content: $icon-turn-down-right;
}

.icon-turn-left-up::before {
  content: $icon-turn-left-up;
}

.icon-turn-down-left::before {
  content: $icon-turn-down-left;
}

.icon-single-2-o::before {
  content: $icon-single-2-o;
}

.icon-stethoscope::before {
  content: $icon-stethoscope;
}

.icon-data-download-c::before {
  content: $icon-data-download-c;
}

.icon-data-download-o::before {
  content: $icon-data-download-o;
}

.icon-star-c::before {
  content: $icon-star-c;
}

.icon-star-o::before {
  content: $icon-star-o;
}

