<template>
  <component :is="component" :href="link" :target="target" class="medimo-tr tr no-gutters row" v-bind:class="{'selected': is_selected, 'keynav': keynav, 'disabled': disabled, 'inactive': inactive}"
             :uid="uid"
             :id="'medimo-tr-' + id"
             ref="medimo-tr"
             @click.left.exact="activate"
  >
    <slot></slot>
  </component>
</template>

<script>
import WorksWithParentsSiblingsAndChildren from '@/vue/mixins/WorksWithParentsSiblingsAndChildren';
import NavigatesProgrammatically from '@/vue/mixins/navigation/NavigatesProgrammatically';

export default {
  components: {},

  emits: ['click','triggered','selected'],

  props: {
    'index': {}, // Needed to determine the order in which to place the rows.
    'keynav': {default: true},

    // Set to TRUE to disable the Click and hover
    'disabled': {default: false},

    // Set to TRUE to make it behave like a regular, static row with no frills
    'inactive': {default: false},

    // Deze zorgt voor de weergave van een link in de browser
    'link': {default: ''},
    'target': {default: ''},

    'ignoreClick': {default: ''},

    // Deze kun je op false zetten waardoor alleen de link te zien is, maar klikken niet navigeert
    // dat kun je dan zelf regelen via het @triggered event
    'navigateOnClick': {default: true},
    // Set to TRUE to force a hard navigation, without VueRouter or any other programmatic navigation, plain old link
    'navigateHard': {default: false},

    // Deze 2 kun je gebruiken om na een table load een specifieke regel op basis van ID alvast te selecteren
    // Bijv bij snel heen en weer navigeren, of deeplinken. Wel oppassen dat de waarde in de zichtbare
    // waardes zit. Anders moeten ook de instellingen opgeslagen worden / zijn met het sterretje
    'id': {default: null},
    'auto_select_id': {default: null}, // Gebruik deze om de regel te selecteren / highlighten
    'auto_trigger_id': {type: [Number, null], default: null}, // Gebruik deze om hem te triggeren, alsof de regel geklikt wordt
  },

  mixins: [
    WorksWithParentsSiblingsAndChildren,
    NavigatesProgrammatically,
  ],

  data: function () {
    return {
      // This is a local switch and not a computed property because if the tables are switched, it can still
      // remain a selected row in an unselected table
      is_selected: false,
      parentMedimoTableUid: 0,

      to: '',
    };
  },

  computed: {
    component() {
      if (this.link.length) {
        return 'a';
      }
      return 'div';
    },
    uid() {
      return this._.uid;
    },
    medimoTrUid() {
      return this.uid;
    },
    isInActiveTable() {
      // Here we go up 4 levels to find the MedimoTable parent.
      // Why so much? Generally the TR will be the direct parent, however
      // once you start nesting the TRs into specific row Components for added functionality, things change.
      // So better safe than sorry.
      if (this.keynav === false) {
        return true;
      }

      return this.parentMedimoTableUid === this.activeTableUid;
    },
    inTheMedimoThead() {
      const medimoThead = this.find_matching_parent('medimoTheadUid', 4);
      return medimoThead !== undefined;
    },
    isTheSelectedTableRow() {
      return this.selectedTableRowUid === this.uid;
    },
    activeTableUid() {
      return this.$store.getters['settings/navigation/activeTableUid'];
    },
    selectedTableRowUid() {
      return this.$store.state.settings.navigation.selectedTableRowUid[this.parentMedimoTableUid];
    },
    triggerSelectedTableRow() {
      return this.$store.state.settings.navigation.triggerSelectedTableRow;
    },
    isDisabled() {
      // Would be cleaner with an actual property, but since this is handled by classes for styling purposes, this works
      return this.$el.className.includes('disabled');
    },
    shouldBeAddedToTableRowNavigation() {
      return this.isInActiveTable && this.keynav && !this.inTheMedimoThead;
    },
    shouldBeTriggered() {
      if (this.id !== null && this.auto_trigger_id !== null) {
        return this.id === this.auto_trigger_id;
      }
    },
    shouldBeSelected() {
      if (this.id !== null && this.auto_select_id !== null) {
        return this.id === this.auto_select_id;
      }
    }
  },

  created() {
    //
  },
  unmounted() {
    this.removeFromAvailableTableRows();
  },

  mounted() {
    // Deze moet via een method ipv een computed omdat hij anders soms niet update
    // met een kapotte keynav tot gevolg
    this.parentMedimoTableUid = this.getParentMedimoTableUid();
    this.addToAvailableTableRows();

    // Als deze row geselecteerd moet worden doen we dat:
    if (this.shouldBeTriggered) {
      setTimeout(() => {
        this.triggered();
      });
    } else if (this.shouldBeSelected) {
      this.selectThisTableRow();
    }
    // Als er nog geen row geselecteerd is, doen we dat direct:
    else if (this.selectedTableRowUid === 0) {
      this.$store.commit('settings/navigation/selectFirstTableRow', this.parentMedimoTableUid);
    }

    this.to = this.link;
  },

  methods: {
    getParentMedimoTableUid() {
      if (this.keynav === false) {
        return 0;
      }

      // Stuk netter, we hebben tegenwoordig de mixin die makkelijk parents vind en ook warnings
      // toont als die er niet is. Vind hij niks, sturen we alsnog 0 terug
      // Moet na Vue3 zo - meer tightly coupled en specifiek checkend. Alle hidden values worden met een prod build
      // afgeschermt.
      const parentTable = this.find_matching_parent('medimoTableUid', 6);

      if (!parentTable || typeof parentTable.$el === 'undefined') {
        return 0;
      }
      const medimoTableUid = parseInt(parentTable.$el.getAttribute('medimo-table-uid').replace('table', ''));

      if (Number.isInteger(medimoTableUid)) {
        return medimoTableUid;
      }
      return 0;
    },
    addToAvailableTableRows() {
      if (this.shouldBeAddedToTableRowNavigation) {
        this.$store.commit('settings/navigation/addToAvailableTableRows', {
          tableUid: this.parentMedimoTableUid,
          rowUid: this.uid,
        });
      }
    },
    removeFromAvailableTableRows() {
      if (this.shouldBeAddedToTableRowNavigation) {
        this.$store.commit('settings/navigation/removeFromAvailableTableRows', {
          tableUid: this.parentMedimoTableUid,
          rowUid: this.uid,
        });
      }
    },
    // Activate activates this row outside of the current Active Table.
    // This means it serves 2 purposes: triggering the row, and activating the parent Table
    // By doing so, we allow click navigation to transcend the default keyboard navigation
    activate(event) {
      if (this.ignoreClick) {
        return;
      }

      this.$emit('click');

      if (!this.disabled && !this.navigateHard) {
        event.preventDefault();
        this.navigate();
        this.$store.commit('settings/navigation/addToActiveTableUids', this.parentMedimoTableUid);
        this.triggered();
      }
    },
    navigate() {
      if (this.link.length && this.navigateOnClick) {
        this.to = this.link;
        this.navigateProgrammatically();
      }
    },
    triggered() {
      if (this.isInActiveTable && !this.disabled) {
        this.$emit('triggered');
        this.selectThisTableRow();
      }
    },
    selectThisTableRow() {
      this.$store.commit('settings/navigation/setSelectedTableRowUid', {
        tableUid: this.parentMedimoTableUid,
        rowUid: this.uid
      });
    }
  },

  watch: {
    is_selected(value) {
      if (value) {
        this.$emit('selected');
      }
    },
    isInActiveTable(isInActiveTable) {
      if (isInActiveTable) {
        this.addToAvailableTableRows();
      } else {
        this.removeFromAvailableTableRows();
      }
    },
    selectedTableRowUid(selectedTableRowUid) {
      if (this.isInActiveTable) {
        if (this.isTheSelectedTableRow) {
          // If its in the active table, and this is the row that's being activated, and it is not disabled by read-only, it's selected
          this.is_selected = !this.isDisabled;
          // And trigger the Selected event to update the parent
          this.$emit('selected');
        } else {
          this.is_selected = false;
        }
      }
    },
    triggerSelectedTableRow() {
      // This one runs every time settings.navigation.triggerSelectedTableRow value changes
      if (this.isTheSelectedTableRow && !this.disabled) {
        // When pressing enter use plain link click if navigateHard is enabled
        if (this.navigateHard) {
          this.navigateByLinkClick();
          return;
        }

        this.triggered();
        this.navigate(); // Check of het moet met navigateOnClick, zit in deze functie
      }
    }
  }
};
</script>
<style scoped>
.selected {
  font-weight: 600;
}

.selected.disabled {
  font-weight: normal;
}
</style>
