#medimo_app {
  width: 100%;
}

// Give the modals a minimum height of 80% for the best aspect ratio
.modal-md > .modal-content {
  min-height: calc($modal-md / 16 * 9);
}
.modal-lg > .modal-content {
  min-height: calc($modal-lg / 16 * 9);
}
.modal-sm > .modal-content {
  min-height: calc($modal-sm / 16 * 9);
}
.modal-xl > .modal-content {
  min-height: calc($modal-lg / 16 * 9); // high enough
}
.modal-fullscreen > .modal-content {
  min-height: calc($modal-lg / 16 * 9); // high enough
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  background-color: $white;
  color: $primary;

  .close {
    padding: 0 calc($spacer / 2);
    margin: inherit;
    line-height: 18px;
  }

  .modal-title.right {
    padding-left: calc($spacer / 2);
    border-left: 2px solid $bright-blue;
    color: $dark;
    line-height: 18px;
    font-weight: $font-weight-normal;
  }
}

.medimodal .modal-dialog .modal-body {
  color: $dark;
}

.modal-footer {
  position: relative;
  padding: calc($spacer / 2);

  .scroll-overlay {
    opacity: 0.25;

    $so-height: 20px;
    height: $so-height;
    width: 100%;
    position: absolute;
    top: (0-$so-height);
    left: 0;

    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba($medimo-75, 0) 0%, rgba($medimo-75, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba($medimo-75, 0) 0%, rgba($medimo-75, 1) 100%);
    background: linear-gradient(180deg, rgba($medimo-75, 0) 0%, rgba($medimo-75, 1) 100%);
  }

  & > :not(:first-child) {
    margin-left: 0;
  }
}

// Title text within header
.modal-title {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  font-size: 1.011rem;
  max-height: 20px;
}

.modal-md {
  .modal-title {
    max-height: inherit;
    max-width: 89%;
  }
}


.medimodal {
  @include media-breakpoint-down('sm') {
    // (Extra) large and modals will be covering the entire screen on mobile
    &.modal-lg, &.modal-xl, &.modal-fullscreen {
      > .modal {
        padding-top: 0!important;

        .modal-dialog-scrollable, .modal-content, .modal-body {
          height: 100%;
        }
      }
    }
  }
  // Medium modals will be covering the entire screen on mobile, but not on ipad
  @media (max-width: 767px) {
    &.modal-md {
      > .modal {
        padding-top: 0!important;

        .modal-dialog-scrollable, .modal-content, .modal-body {
          height: 100%;
        }
      }
    }
  }

  .modal {
    display: inherit;
    background-color: rgba(0, 0, 0, 0.35);
    padding-top: 10vh;

    .modal-content {
      border: 1px solid $medimo-10
    }

    &.hide-overlay, &.no-overlay {
      background-color: transparent;
    }

    &.no-overlay {
      padding-top: 0;
      height: 0;
      top: 10vh;
      overflow: visible;
      .modal-dialog {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.33);
      }
    }

    /** Ensures  **/
    .modal-dialog-scrollable .modal-content, .modal-dialog-scrollable .modal-body{
      position:relative;

      //overflow: visible;
      .datetimepicker {
        position: static;

        .pickers-container {
          position: fixed;
        }
      }
    }

    .modal-dialog-scrollable .modal-body {
      // Used to prevent a flickering bottom "scrollable" overlay
      // If your content is hidden because of this, you're doing it wrong ;-)
      overflow-x: hidden;
    }
  }

  .close {
    cursor: pointer;
  }

  @include media-breakpoint-up('md') {
    .modal-dialog-scrollable {
      max-height: 75vh;
    }
  }

  @include media-breakpoint-down(md) {
    .modal-lg, .modal-xl, .modal-fullscreen {
      .modal-dialog {
        width: 95%;
      }

      .modal {
        padding-top: 5vh;
      }

      .modal-content {
        height: 80vh;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .modal-xl {
      width: $modal-lg;
    }
  }

  @include media-breakpoint-up(lg) {
    .modal-xl {
      width: $modal-xl;
    }
    .modal-fullscreen {
      width: $modal-fullscreen;
    }
  }
}
