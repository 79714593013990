.thumbnail {
  left: -4px;
  position: relative;
  top: 3px;


}

.avatar {
  img {
    border-radius: 50px;
    width: 32px;
    height: 32px;
    object-fit: cover;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.50);
  }
}
