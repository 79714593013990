
.medimodal .modal-body:focus {
  outline: 0;
}

#header-close-button {
  top: -1px;
  position: relative;
}

.modal-title {
  [class^=icon] {
    font-size: 15px;
    top: 1px;
    position: relative;
  }
}
