
.button {
  color: #444;
  background-color: #F3F3F3;
  padding: 8px;
  height: 32px !important;
  &.group {
    margin: 10px;
  }
  &.group-logic, &.tree-logic {
    width: 32px;
    text-align: center;
  }
  &.tree-logic {
    margin: 5px;
    vertical-align: bottom;
  }
}
.filter-items {
  border: 1px solid #E3E3E3;
  margin: 5px 5px 5px 0;
  padding: 5px 5px 10px;
  min-width: 365px;
  display: inline-block;
  .filter-item {
    margin-bottom: 5px;
    .add-item-button, .add-item-spacer, .delete-item-button {
      padding: 8px;
      height: 32px;
      width: 32px;
      vertical-align: middle;
      display: inline-block;
      &.add-item-button, &.delete-item-button {
        color: #757575;
        cursor: pointer;
      }
    }
  }
}
