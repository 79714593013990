html > body.acceptance {
  background-color: lighten($green, 30%);
}

html > body.development {
  background-color: lighten($red, 30%);
}

html > body.development.v2 {
  background-color: lighten($purple, 30%);
}

html > body.not-logged-in.production {
  background-color: $secondary;
}
