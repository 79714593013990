/* Selected Tags */
.vs__selected {
  display: flex;
  align-items: center;
  background-color: $vs-selected-bg;
  border: $vs-selected-border-width $vs-selected-border-style $vs-selected-border-color;
  border-radius: $vs-border-radius;
  color: map_get($vs-colors, 'dark');
  line-height: $vs-component-line-height;
  margin: 4px 2px 0px 2px;
  padding: 0 0.25em;
  z-index: 0;
}

.vs__deselect {
  display: inline-flex;
  appearance: none;
  margin-left: 4px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: $vs-controls-color;
  text-shadow: $vs-controls-deselect-text-shadow;
}

/* States */

.vs--single {
  .vs__selected {
    background-color: transparent;
    border-color: transparent;
  }
  &.vs--open .vs__selected,
  &.vs--loading .vs__selected {
    //position: absolute;
    opacity: .4;
  }
  &.vs--searching .vs__selected {
    display: none;
  }
}
