.medimo-loader-1 {
  display: inline-block;
  position: relative;
}

.medimo-loader-1 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 6px;
  border: 6px solid $primary;
  border-radius: 50%;
  animation: medimo-loader-1 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary transparent transparent transparent;
}

.medimo-loader-1 div:nth-child(1) {
  animation-delay: -0.45s;
}

.medimo-loader-1 div:nth-child(2) {
  animation-delay: -0.3s;
}

.medimo-loader-1 div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes medimo-loader-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
