@media print {

  #content-container {
    // We hebben een minimale page width in pixels vanuit de header.blade
    // maar de print versie werkt anders, dus op deze manier forcen we een 100% width, ongeacht de pixels,
    // of hoe een specifieke browser de print breedte berekend
    width: 100%!important;
    max-width: 100%!important;
  }

  html > body, #medimo_app {
    background-color: white!important; // Bespaart toner
  }

  #home-link-logo {
    display: none!important; // Anders gaan die over de breadcrumbs o op Safari
  }

  #footer {
    position: relative; // Zo staat hij altijd onderaan de laatste pagina, ipv onderaan de eerste pagina
  }

  /**
      Onderstaand locked de responsiveness van het grid
   */
  //
  .btn-sm-block, .home-medimo-link, .medimo.dropdown .btn-group  {
    width: inherit;
  }
  .medimo.dropdown {
    display: inline;
  }

  #mutation-view, #patient-status-page {
    .col-1, .col-sm-1, .col-md-1, .col-lg-1, {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }

    .col-2, .col-sm-2, .col-md-2, .col-lg-2, {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }

    .col-3, .col-sm-3, .col-md-3, .col-lg-3, {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-4, .col-sm-4, .col-md-4, .col-lg-4, {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    .col-5, .col-sm-5, .col-md-5, .col-lg-5, {
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }

    .col-6, .col-sm-6, .col-md-6, .col-lg-6, {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-7, .col-sm-7, .col-md-7, .col-lg-7, {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }

    .col-8, .col-sm-8, .col-md-8, .col-lg-8, {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }

    .col-9, .col-sm-9, .col-md-9, .col-lg-9, {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-10, .col-sm-10, .col-md-10, .col-lg-10, {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }

    .col-11, .col-sm-11, .col-md-11, .col-lg-11, {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }

    .col-12, .col-sm-12, .col-md-12, .col-lg-12, {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .col-print-1 {width:8%!important; max-width:8%!important; float:left;}
  .col-print-2 {width:16%!important; max-width:16%!important; float:left;}
  .col-print-3 {width:25%!important; max-width:25%!important; float:left;}
  .col-print-4 {width:33%!important; max-width:33%!important; float:left;}
  .col-print-5 {width:42%!important; max-width:42%!important; float:left;}
  .col-print-6 {width:50%!important; max-width:50%!important; float:left;}
  .col-print-7 {width:58%!important; max-width:58%!important; float:left;}
  .col-print-8 {width:66%!important; max-width:66%!important; float:left;}
  .col-print-9 {width:75%!important; max-width:75%!important; float:left;}
  .col-print-10 {width:83%!important; max-width:83%!important; float:left;}
  .col-print-11 {width:92%!important; max-width:92%!important; float:left;}
  .col-print-12 {width:100%!important; max-width:100%!important; float:left;}


}

// Edge Chromium ondersteund de "orientation: landscape / portrait" niet....
// Tot 40em is de breedte van een pagina in portrait
// Werken met fixed page width en height werkt niet. Hardcoden werkt niet voor alle browsers
// dit was de oplossing die compatible was met alle browsers
@media print and (max-width: 40em) {
//@media print and (orientation: portrait) {
  // Met deze styling zorgen we er voor dat de breedte in de portrait orientatie van een print
  // altijd 80% is van het geheel. Waarom? Vraag je je af... Omdat de browser goden in al hun wijsheid
  // bepalen dat de breedte van een print pagina in pixels nog altijd de volledige breedte heeft
  // maar deze onder water toch anders is, en een deel afkapt.
  // Op deze manier kunnen we op simpele wijze toch een correcte weergave tonen van Medimo
  // in print die lijkt op de scherm weergave, zonder dat het mobile wordt.
  //
  // Safari is broken:
  // https://stackoverflow.com/questions/38162893/javascript-window-print-dialog-does-not-respect-mediaqueries-in-safari-9-1-el
  // https://stackoverflow.com/questions/34132047/media-query-for-print-portrait-view-not-working-in-safari-9
  #content-container {
    width: 85%!important;
    max-width: 85%!important;
  }
  .container {
    margin-right: 0!important;
    margin-left: 0!important;
  }
}
