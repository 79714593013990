
  @import 'quill/dist/quill.snow.css';

  div {
    width: v-bind(width);
    height: v-bind(height);

    :deep(.ql-editor) { // Making it more specific than Quill's own css.
      p, ol, ul { // Reset margin and padding.
        margin: 0;
        padding: 0;
      }

      p {
        padding: 15px 0 0 0;
      }

      ol {
        padding: 15px 0 0 0;
      }

      p:first-child, ol:first-child, ul:first-child {
        padding: 0;
      }

      li:not(.ql-direction-rtl)::before {
        margin-left: -23px;
      }
    }
  }
