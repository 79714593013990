// Medimo primary colors
$primary: #1C4B84; //#194c81
$medimo: $primary;
$medimo-75: #5579A3;
$medimo-50: #8DA5C1;
$medimo-35: lighten($medimo-50, 10%);
$medimo-25: #C6D2E0;
$medimo-15: #DDE4ED;
$medimo-10: #E8EDF2;
$medimo-5: #F9FAFC; //#F3F6F9;

// Medimo secondary colors
$blue: $medimo;
$bright-blue: #70A3CF;
$medimo-secondary: $bright-blue;
$bright-light: lighten($bright-blue, 27%);
$red: #CD4D44;
$light-red: lighten($red, 25%);
$bg-red: lighten($red, 42%);
$yellow: #F6E01B;
$green: #5CC082;
$bg-green: lighten($green, 35%);
$light-green: lighten($green, 25%);
$orange: #F1A237;
$bg-orange: lighten($orange, 35%);
$brown: #8C7000;
$gray-400: $medimo-25;
$gray-600: $medimo-50;
$gray-500: #808080;
$gray-800: #343a40;
$gray-900: #212529;
$dark: $gray-900;
$black: $gray-900;

// Color aliases
$light-gray: $medimo-10;
$gray: $medimo-25;
$dark-blue: $primary; //primary
$shaded-blue: $medimo-75; //75%, for lines
$background-shade: $medimo-5; //5%, for shades in white
$background-contrast: $medimo-10; //10%, for contrast next to shades

$theme-colors: (
        "start": $green,
        "green": $green,
        "light-green": $light-green,
        "red": $red,
        "light-red": $light-red,
        "stop": $red,
        "stno": $red,
        "danger": $red,
        "warning": $orange,
        "medimo-5": $medimo-5,
        "medimo-10": $medimo-10,
        "medimo-15": $medimo-15,
        "medimo-25": $medimo-25,
        "medimo-35": $medimo-35,
        "medimo-50": $medimo-50,
        "medimo-75": $medimo-75,
        "medimo": $primary,
        "blue": $primary,
        "bright-blue": $bright-blue,
        "info": $bright-blue,
        "gray": $medimo-50,
        "gray-500": $gray-500,
        "dark": $dark,
);


/*
 |--------------------------------------------------------------------------
 | Medimo Base Variables
 |--------------------------------------------------------------------------
 |
 | Variables used in both Bootstrap and Medimo .scss files
 |
 |
 */
// Grid
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 769px,
        lg: 1250px,
        xl: 1500px,
        xxl: 1823px
);
$regular-max-width: 1152px; //1024px;//1152px; // 1475px

// Container widths
$container-max-widths: (
        sm: 768px,
        md: 1080px,
        lg: $regular-max-width,
        xl: 1475px,
        xxl: 1675px,
);

$spacer: .7220rem;
$spacers: (
        6: ($spacer * 4),
        7: ($spacer * 5),
        8: ($spacer * 7.5),
        9: ($spacer * 10),
        10: ($spacer * 12.5),
        11: ($spacer * 15),
        12: ($spacer * 17.5),
);

$spacer1: $spacer * .25;
$spacer2: $spacer * .5;
$spacer3: $spacer;
$spacer4: $spacer * 1.5;
$spacer5: $spacer * 3;

// Text
$font-family-base: 'Open Sans', 'sans-serif';
$font-size-base: 0.8660rem; // Assumes the browser default, typically `12px`
$small-font-size: 0.7220rem; //10px

$line-height-base: 1.5;
$headings-line-height: $line-height-base;
$line-height-base-px: 24px;

$enable-shadows: false;
$enable-validation-icons: false;

$button-color: $primary;
$button-hover-color: $primary;
$menu-color: $primary;
$menu-hover-color: $medimo-75;

$btn-border-width: 0px;

$btn-box-shadow: none;
$btn-focus-width: 0px;
$btn-focus-box-shadow: $btn-box-shadow;
$btn-disabled-opacity: .65;
$btn-active-box-shadow: $btn-box-shadow;

$box-shadow-sm: 0 0 5px rgba(0, 0, 0, .05);
$box-shadow: 0 0 7px rgba(0, 0, 0, .1);
$box-shadow-lg: 0 0 7px rgba(0, 0, 0, .1);

$breadcrumb-item-padding: 10px;

$btn-height: 36px;
$input-height: 32px;
$input-padding: 10px;
$input-btn-padding-y: .3rem;
$input-btn-padding-x: .75rem;
$input-border-color: $medimo-25;
$input-height-inner-double: calc(#{$line-height-base * 1.5em} + #{$input-btn-padding-y * 2});

// Medimo Page Component
$page-component-padding-top: 20px;
$page-component-padding-right: 20px;
$page-component-padding-bottom: 20px;
$page-component-padding-left: 20px;
$page-component-padding: $page-component-padding-top $page-component-padding-right $page-component-padding-bottom $page-component-padding-left;


// Navbar
$navbar-height: 50px;

$background-color-base: $medimo-10;

$border-radius: 3px;


$on-overview-page-max-width: (924 + 2*$page-component-padding-left);
$footer-height: 40px;

// Medimo-tables
$table-cell-padding: 8px !default;
$table-tr-height: 40px;
$table-accent-bg: $background-shade;
$table-border-color: $background-contrast;
$table-button-color: $medimo-25;
$breadcrumb-bg: white;

// Medimo-modals
$modal-fullscreen: 95%;
$modal-xl: $regular-max-width;
$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 350px;
$modal-header-border-color: $medimo-25;
$modal-header-padding-y: 0.75rem !default;
$modal-header-padding-x: 0.75rem !default;

$h1-font-size: $font-size-base * 1.4;
$h2-font-size: $font-size-base * 1.4;
$h3-font-size: $font-size-base * 1.2;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;

$headings-font-family: 'Lato', 'Open Sans', 'sans-serif';
$headings-font-weight: 600;
$headings-margin-bottom: 0;

$badge-padding-y: .5em !default;
$badge-padding-x: .75em !default;

// Dont let bootstrap change our colors
$alert-bg-level: 0;
$alert-border-level: 0;
$alert-color-level: 0;
// Change padding for alerts
$alert-padding-y: calc($spacer / 2);
$alert-padding-x: calc($spacer / 2);

$modal-title-line-height: 18px;

$caret-width: .3em;

$input-disabled-bg: $medimo-5;


// MedimoSwitch
$custom-control-indicator-border-color: $gray-600; // OFF
$custom-control-indicator-checked-color: $medimo; // ON
$custom-control-indicator-checked-border-color: $medimo; // ON
