$results-width: 350px;
$top-nav-height: 50px;

#nav-search {

  input {
    border-radius: $border-radius;
    width: 150px;
    padding-left: 32px;
    min-height: 32px;
    border: 1px solid $medimo-25;
  }

  #nav-search-query {
    padding: 6px 5px 5px 32px;
    line-height: $line-height-base;
  }

  #nav-search-query:focus {
    outline: 0;
    outline-color: $dark-blue;
  }

  #nav-search-query.open, #nav-search-query:focus {
    width: $results-width;
  }

  input#nav-search-query::placeholder {
    padding-left: 0;
    margin-top: 5px;
  }

  input#nav-search-query.hidden::placeholder {
    // We hide the placeholder text on select
    color: rgba(0, 0, 0, 0);
  }

  .medimo-icon, svg.svg-inline--fa {

    &.search {
      position: absolute;
      left: 9px;
      top: 8px;
    }

    & {
      /*float: left;*/
      opacity: 0.5;
    }
  }

  .medimo-icon.hidden, svg.svg-inline--fa.hidden {
    opacity: 0;
  }

}

#medimo-notifications .list-group .link a {
  line-height: 1.5 !important;
}

#nav-search, #medimo-notifications {
  display: inline;
  position: relative;

  .list-group {
    position: absolute;
    top: 50px;
    right: 0;
    width: $results-width;
    max-height: 75vh;
    margin-left: auto;
    margin-right: inherit;

    color: $menu-color;
    border-radius: $border-radius;
    background-color: white;
    left: 0;
    z-index: 10;
    box-shadow: $box-shadow;

    a {
      color: $menu-color;
    }

    .link:hover, .link:focus, .link.selected {
      cursor: pointer;
      outline: none;

      & > div {
        background-color: $menu-hover-color;
      }

      a, .text-primary {
        color: white !important;
        text-decoration: none;
      }
    }

    .link a {
      color: $menu-color;
      padding: calc($spacer / 2);
      line-height: ($line-height-base * 2);
    }
  }
}


#overview-parent {
  .overview-link {
    //transition: all 0.5s ease-out;

    .title {
      font-size: 0.939rem;
      font-weight: $headings-font-weight;
    }
  }
}
#overview-title {
  img {
    height: 22px;
    top: 3px;
    position: relative;
  }
}

.top-nav-logo {
  width: 135px;
  position: relative;
  top: 9px;
}

.breadcrumbs {
  position: relative;
  top: 5px;
}

#top-nav {

  box-shadow: $box-shadow;
  height: $top-nav-height;

  #admin-side-panel {
    box-shadow: -10px 0px 5px rgba(0,0,0,0.05);

    z-index: 1;
    position: absolute;
    top: $top-nav-height;
    right: 0;
    width: 350px;
    min-height: 100vh;
    background-color: white;
    border-top: $card-border-width solid $card-border-color;
    border-left: $card-border-width solid $card-border-color;

    .card-header, .card {
      border-radius: 0;
      border-left: none;
    }
    .card-header {
      font-weight: bolder;
      color: $medimo;
      background-color: $medimo-5;
    }
  }

  ul.navbar-nav {
    z-index: 2;
    margin: 0;
  }

  li.nav-item {
    line-height: $line-height-base;
    padding-left: .5rem;
    padding-right: .5rem;

    .btn {
      padding: 0;
    }
  }

  #top-nav-dropdown {

    @include media-breakpoint-down('sm') {
      &.medimo.dropdown {
        top: 4px;
      }
    }

    .btn.btn-light {
      background-color: white;
      color: $primary;
      border: none;
    }

    .btn.dropdown-toggle::after {
      content: none;
    }

    button.btn.btn-light:hover {
      background-color: white !important;
      color: inherit !important;
    }

    &.medimo.dropdown .medimo-dropdown-icon {
      top: 5px
    }

    .medimo-dropdown-menu {
      position: absolute;
      right: -16px;
      top: 40px;

      .dropdown-item {
        padding: 0.45rem $spacer 0.45rem 0.9rem;
        .medimo-icon, .fa-icons {
          position: relative;
          top: 3px;
          margin-right: 0.5rem;
        }
        .fa-icons {
          font-size: 18px;
        }
      }
    }

    hr {
      padding: 0;
    }

  }
}

.nav-search-divider {
  font-weight: 600;

  & > div {
    background: $medimo-10;
    padding: calc($spacer / 2);
  }
}


/*
 |--------------------------------------------------------------------------
 | Breakpoints
 |--------------------------------------------------------------------------
 |
 |
 |
 |
 */
/* SMALL */
@include media-breakpoint-down(sm) {
  #top-nav {
    box-shadow: $box-shadow-sm;

    //position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
  }
}

/* MEDIUM AND DOWN */
@include media-breakpoint-down(md) {
  #nav-search {
    input {
      width: 150px;
    }

    .results {
      width: 75vw;
    }
  }
}

/* XS SMALL */
@include media-breakpoint-down(xs) {
  #nav-search, #medimo-notifications {
    input {
      width: 25px;
      border: none;
    }

    .medimo-icon.search {
      font-size: 18px !important;
    }

    .container.list-group {
      left: 0;
      top: 65px;
      position: fixed;
    }

    #nav-search-query.open {
      width: 200px;
    }

    .list-group {
      /*width: 100vw;*/
      right: 0;

      position: fixed;
      margin: auto;
      top: 65px;
      width: 98vw;
    }
  }
}
