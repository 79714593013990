<template>
    <div :class="'chatMessage ' + (me_or_other)">
      <b><span v-if="show_username">{{ username }}</span> {{ created_at }}</b><br />
      <pre style="white-space: pre-wrap;">{{ message.message }}</pre>
    </div>
</template>

<script>
import Dates from '/vue/utility/dates.js';

export default {
  components: {},

  props: {
    'message': {required: true},
    'show_username': {default: true}
  },

  mixins: [

  ],

  data: function () {
    return {};
  },

  computed: {
    username() {
      return this.message.createdByUserName + ' - ';
    },
    created_at() {
      return Dates.setDate(this.message.createdAt).humanReadableDateWithTime();
    },
    me_or_other() {

      const current_user = this.$store.state.current_user.data;

      if (this.message.createdByUserName === 'Medimo') {
        return 'system';
      } else if (this.message.createdByUserName === current_user.name) {
        return 'me';
      } else {
        return 'other';
      }
    },
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>

<style>
    div.chatMessage {
        margin: 0 0 1em;
        border-radius: 2px;
        padding: .5em 1em;
        max-width: 85%;
        clear: both;
        position: relative;
    }
    div.me {
        float: right;
        background-color: #e8edf2;
    }
    div.other {
        float: left;
        background-color: #f9fafc;
    }
    div.system {
        float: left;
        background-color: #70a3cf;
    }
</style>
