
#protocol-builder {
  .rule .remove {
    cursor: pointer;
  }
  svg.svg-inline--fa {
    color: #bababa;
  }
  table {
    border-collapse: separate;
    border-spacing: 2px;
    td {
      width: 220px;
      min-height: 5px;
      text-align: center;
      position: relative;
    }
  }
  #addRule {
    padding: 10px 0 10px 2px;
    cursor:pointer;
  }
}
