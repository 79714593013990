@import 'forms/_datetime_picker';
@import 'forms/_select2';
@import 'forms/_status-forms';

.form-control:focus {
  border: $border-width solid $border-color;
  box-shadow: none;
}

//.medimo-tooltip {
//  width: 18px;
//}

form.medimo-form {

  .medimo-form-column {
    padding-right: 5px;
    padding-left: 5px;
  }

  input.form-control, textarea.form-control, .vs__dropdown-toggle.form-control {
    border: $input-border-width solid $input-border-color;
    border-radius: $border-radius;
    //padding-left: $spacer;
    line-height: $line-height-base-px;
  }

  label {
    // By adding the border width it keeps both at the same height;
    line-height: $input-height;
  }

  label {
    //font-weight: 600;
    color: $dark;

    // When it's a full row one, we want the label and input to be closer together
    &.d-block {
      height: $input-height - 2;
    }
  }

  .form-group {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  input[type=text], textarea {
    width: 100%;
  }

  .medimo-input input, .medimo-textarea textarea {
    border-radius: $border-radius;
    padding: 5px 10px;
    border: 1px solid $medimo-25;
  }

  .medimo-input {
    height: $input-height;

    input {
      height: $input-height;
      line-height: $input-height;
    }

    input::placeholder {
      color: $medimo-50;
    }

    &.with-subtext {
      height: ($input-height + 16);
    }

    input[type=number].clean-input {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .medimo-input, .medimo-textarea, .medimo-select2 {
    .medimo-tooltip {
      position: absolute;
      right: 6px;
      top: 7px;
    }

    &.is-invalid {
      textarea, span.checkmark {
        border-color: $red;
      }
    }
  }

  .medimo-input.is-invalid input {
    border-color: $red; // Nodig om rand om validated input rood te maken
  }

  .medimo-select2.is-invalid {
      border: $input-border-width solid $red; // Nodig om de hele select2 rood te maken ipv alleen de input
      border-radius: 5px;
  }

  .medimo-textarea textarea {
    line-height: $line-height-base;
  }

  .medimo-vue-select2, {
    &.is-invalid {
      input {
        border-color: transparent;
      }
    }
  }

  .medimo-select2 {
    &.is-invalid {
      .medimo-tooltip {
        right: 24px;
      }
    }
  }

  /** The Select2 needs to be moved in a tad more due to the caret **/
  .medimo-select2 span.medimo-tooltip {
    right: 1.5em;
  }

  .invalid-feedback, label.is-invalid, .is-invalid label {
    /*border: 1px solid red;*/
    color: $danger;
    //font-weight: 600;
  }

  .fa {
    color: $medimo;
  }

  /*
   |--------------------------------------------------------------------------
   | Custom Radio Button
   |--------------------------------------------------------------------------
   |
   |
   |
   |
   */
  label.medimo-radio {
    color: $dark;
    font-weight: normal;
  }

  .medimo-radio {
    .medimo-icon {
      top: 6px;
      font-size: 18px;
      color: $medimo;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .kenmerkenrood {
    color: $red;
  }
}

/*
 |--------------------------------------------------------------------------
 | Custom Checkbox
 |--------------------------------------------------------------------------
 |
 |
 |
 |
 */
$checkbox-height: 18px;

.medimo-checkbox {
  margin-right: calc($spacer / 3);

  .checkbox-label, .form-control {
    line-height: $input-height;
  }

  //.form-control {
  //  height: 0;
  //}

  .medimo-tooltip {
    margin-left: ($checkbox-height * 1.75);

    .medimo-icon {
      top: 1px;
    }
  }

  .checkbox-label.inline.right.with-help-text {
    margin-left: 31px;
  }

  &.just_the_checkbox {
    display: inline;

    .checkbox-container {
      display: inline;
    }
  }
}

/* Customize the label (the container) */
.medimo-checkbox {
  &.just_the_checkbox {
    .checkbox-container.approverCheckbox {
      width: 16px;
    }
  }
  .checkbox-container {
    width: 28px;
    display: block;
    position: relative;
    //padding-left: 25px;
    cursor: pointer;
    height: $checkbox-height;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox-label {
    font-weight: normal;
    color: $dark;
    cursor: default;
  }
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  border-radius: $border-radius;
  position: absolute;
  height: $checkbox-height;
  width: $checkbox-height;
  background-color: white;
  border: 1px solid $medimo-25;

  &.disabled {
    background-color: $medimo-15 !important;
  }
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: $medimo-15;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input.isChecked ~ .checkmark {
  background-color: $medimo;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container
{
  input.isChecked ~ .checkmark:after {
    display: block;
  }
  input.isIndeterminate ~ .checkmark:after {
    display: block;
  }
}

/* When the checkbox is indeterminate, add a light blue background */
.checkbox-container input.isIndeterminate ~ .checkmark {
  background-color: $bright-light;
}

/** Style the intederminate checkmark/indicator */
.checkbox-container input.isIndeterminate ~ .checkmark:after {
  left: 3px;
  top: 7px;
  width: 10px;
  border-width: 3px 0 0 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.is-invalid .checkbox-container .checkmark {
  border-color: $danger;
  //&::after {
  //  border-color: $danger;
  //}
}

/*
 |--------------------------------------------------------------------------
 | Datetimepicker
 |--------------------------------------------------------------------------
 |
 |
 |
 |
 */
$date-time-picker-padding: 24px;
.medimo-datetime-picker {
  //.with-help-text {
  //  .form-control.datetime-text-input {
  //    padding-right: $date-time-picker-padding;
  //  }
  //}

  .medimo-icon {
    color: $medimo;
  }
}

html > body.v2, html > body.v3 {
  .xdsoft_datetimepicker {
    .xdsoft_next, .xdsoft_prev, .xdsoft_today_button {
      opacity: 1;
    }

    .xdsoft_calendar th, di .xdsoft_calendar td {
      color: $medimo;
      background: $medimo-10;
    }

    .xdsoft_calendar td.xdsoft_today {
      background: $button-color;
      color: white;

      &:hover {
        background: $button-hover-color !important;
      }
    }

    .xdsoft_calendar td:hover, .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
      background: $button-hover-color !important;
    }

    button {
      background-color: white;

      &:hover {
        background-color: $button-hover-color !important;
      }
    }
  }
}
