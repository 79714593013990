
/**
  Nodig om de styles te counteren voor de modal, aangezien deze hier nested is in NavSearch
  Weer net ff anders dan de ReportLink dus voor flexibiliteit een volledige definitie
 **/
#top-nav li.nav-item .nav-search-authorisation-list-link .medimodal .btn {
  padding: 0.3rem 0.75rem;
}
#nav-search .list-group .nav-search-authorisation-list-link .medimodal {
  .btn-primary, .btn:hover, .btn-medimo {
    color: white;
  }
}

#nav-search .nav-search-authorisation-list-link .medimodal {
  input {
    border-radius: 3px;
    width: 100%;
    padding-left: 10px;
    min-height: 0;
  }

  svg.svg-inline--fa {
    opacity: 1;
  }
}
