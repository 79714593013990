
.notification {
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);
  background-color: $medimo-10;

  .medimo-icon {
    top: 3px;
  }

  .body {
    color: $medimo;
    padding: 1px 0 5px 0;
  }
}

@each $color, $value in $theme-colors {
  .notification-#{$color} {
    @include notification-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));

    & {
      border-width: 0 0 0 5px;
    }

    .title {
      svg.svg-inline--fa {
        @include notification-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));

        &.fa-clock-rotate-left {
          color: $medimo;
        }
      }
      & {
        color: $medimo;
      }
    }
  }

  .notification-danger {
    background-color: $bg-red;
    .title {
      color: $danger;
      font-weight: $font-weight-bold;
    }
  }
  .notification-warning {
    background-color: $bg-orange;
  }
  .notification-info {
    background-color: $medimo-10;
  }
  .notification-success {
    background-color: $bg-green;
  }
}
