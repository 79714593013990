
.btn-secondary {
  color: white;
}

.icon-only {
  svg.svg-inline--fa {
    margin-right: 0.1805rem;
  }
}
