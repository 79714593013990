
#login-container {
  .ce {
    position: absolute;
    height: 18px;
    bottom: 0;
    right: 0;
  }
}

$padding-login-form: 15px 18px;

#login-form {
  border-radius: $border-radius;
  background-color: white;
  padding: $padding-login-form;

  .logo {
    width: 100%;
  }
}

#login-form-bottom {
  position: relative;
  padding: $padding-login-form;
  color: white;
  text-align: center;

  a {
    color: white;
  }

  a:hover {
    cursor: pointer;
  }
}