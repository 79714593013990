@import '~select2/src/scss/core';

/*
 |--------------------------------------------------------------------------
 | Custom Styling Options
 |--------------------------------------------------------------------------
 |
 | Here we overwrite some of the /src/scss/theme/default values
 |
 |
 */
//.select2-container--default .select2-results__option--highlighted[aria-selected] {
//  background-color: $medimo;
//}
body.v3 {
    .select2-container--default.select2-container--disabled .select2-selection--multiple {
      background-color: $medimo-5!important;
      cursor: default;
    }
}

//.select2-container--default {
//
//  .select2-search--dropdown {
//    .select2-search__field {
//      border: 1px solid $medimo-10;
//    }
//  }
//
//  .select2-results__option {
//    &[aria-selected=true] {
//      background-color: $medimo;
//    }
//  }
//}

// This meant is meant as a warning on the dev builds to ensure proper component usage.
// Should be moved to it's own stylesheet at some point when we have these for all components.
body.development.v3 {
  .select2-results__option:before {
    content: '!!Use MedimoVueSelect2.vue!! ';
  }

  .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: red;
  }

  .select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: $medimo-5;
    cursor: default;
  }

  .medimo-select2 {
    .select2-results__option:before {
      content: '';
    }
    .select2-container--default .select2-results__option[aria-selected=true] {
      background-color: $medimo-25;
    }
  }
}

.medimo-select2 {

  &.bg-primary {
    background: inherit !important;

    .selection > .select2-selection.select2-selection--single {
      background-color: $primary;
      color: white;

      .select2-selection__rendered {
        color: white;
      }

      .select2-selection__arrow b {
        border-color: white transparent transparent transparent;
      }
    }

  }

  .select2-container .select2-search--inline .select2-search__field {
    margin-top: 6px;

  }
  input.select2-search__field::placeholder {
    color: $medimo-50;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding-left: 9px;
  }

  .select2-results__option {
    padding-left: 9px;
  }

  .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: $medimo-25;
  }

  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $medimo;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 10px;
    line-height: $input-height;
  }

  .select2-container--default .select2-selection--single {
    border: $input-border-width solid $input-border-color;
    height: $input-height;

    &:focus {
      outline: none;
    }
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: $medimo transparent transparent transparent;
  }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent $medimo transparent;
  }

  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: $medimo-50;
  }

  .select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: $input-border-color;
    border-radius: $border-radius;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 0;
    height: $input-height;
  }

  ul.select2-results__options.select2-results__options--nested > li.select2-results__option {
    height: $input-height;
    line-height: calc($input-height / 2) + 1;
    padding: 7px;
  }

  .editable-select2 {
    position: absolute !important;
    z-index: 2;
    width: 80%;

    input {
      border-right: none!important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:focus {
        outline: none;
      }
    }
  }
}
