/* Dropdown Menu */

$border-width: $vs-border-width;
$border-style: solid;
$border-color: $vs-border-color;
$border-radius: $vs-border-radius;
$box-shadow: $vs-dropdown-box-shadow;

$bg-color: $vs-dropdown-bg;
$z-index: $vs-dropdown-z-index;
$min-width: $vs-dropdown-min-width;
$max-height: $vs-dropdown-max-height;

.vs__dropdown-menu {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - #{$border-width}); // -{#$border-width} here ensures the left and right borders of the dropdown appear flush with the toggle.
  left: 0;
  z-index: $z-index;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  max-height: $max-height;
  min-width: $min-width;
  overflow-y: auto;
  box-shadow: $box-shadow;
  border: $border-width $border-style $border-color;
  border-top-style: none;
  border-radius: 0 0 $border-radius $border-radius;
  text-align: left;
  list-style: none;
  background: $bg-color;
}

.vs__no-options {
  text-align: center;
}
