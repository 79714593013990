.overview-page {
  h1 {
    margin: 0;
  }
}

.overview-link {
  min-height: 40px;
  border-right: rgba(0, 0, 0, 0) 0px solid !important;
  background-color: rgba(0, 0, 0, 0) !important;

  .overview-link-row {
    border-radius: $border-radius;
    border-left: $bright-blue 12px solid;
  }

  /** Removes the hover from the description, doesn't work the other way around **/
  div:hover .title {
    text-decoration: underline !important;
  }

  div:hover {
    text-decoration: none !important;
  }

  .overview-link-container {
    background-color: white;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .row {
      /*<!--border-radius: $border-radius;-->*/
    }
  }

  .badge {
    min-width: 25px;
  }
}

.overview-link:focus {
  outline: none;
}

.overview-link-row.is_selected {
  border-left: $dark-blue 12px solid;

  font-weight: normal;

  .title {
    color: $dark-blue;
  }

  .description {
    color: $dark-blue !important;
    opacity: 0.75;
  }
}