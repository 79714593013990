
.medication-guard-chat-modal {
  #btnToggleChat.btn.btn-sm i.medimo-icon.icon-b-chat {
    top: 4px;
  }
  #btnToggleNote.btn.btn-sm i.medimo-icon.icon-recipe {
    top: 3px;
  }

  .fa-reply-all {
    top: 1px;
    position: relative;
  }
}
