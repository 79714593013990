.breadcrumb {
  margin-bottom: 0;
  padding: 0;

  .breadcrumb-item + .breadcrumb-item::before {
    content: "";
    padding-right: 0;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
  }

  .breadcrumb-item {
    padding: 0;
    margin: 0;
    margin-left: 0px;
    margin-right: 4px;
    line-height: 22px;

    .medimo-icon, svg.svg-inline--fa {
      margin-left: 5px;
      margin-right: 0px;
      color: $bright-blue;
    }
  }
}

#breadcrumb-container {
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
  z-index: 1;
  max-width: $regular-max-width;
  /*padding-left: 0;*/
  background: transparent;
  box-shadow: none;

  &.overview-page {
    max-width: $on-overview-page-max-width;
    padding-left: $page-component-padding-left;
  }
}

@include media-breakpoint-down(sm) {

  #breadcrumbs {
    .breadcrumb {
      padding-bottom: 0.2rem !important;
    }
  }
}
