
@import "/variables";

.schema-button {
  white-space: nowrap;
}

.schema-button.badge.badge-sm {
  color: $primary;
  font-size: 10px;
  background-color: transparent;
  border: 1px solid $primary;
}

