.medimo-tabs.tabs {

  border-radius: $border-radius;
  &.no-border-radius-top {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
  }
  &.no-border-radius-bottom {
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }

  .tab-content {
    //min-height: 100px;
    background-color: white;
    padding: $spacer * 2;
    //border: 1px solid $medimo-25;
    border-radius: $border-radius;
    border: 1px solid;
    border-color: #fff $medimo-25 $medimo-25;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-width: 0;

    &.no-border-radius-bottom {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    ol {
      margin-left: 12px;
    }
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    //border: 1px solid $medimo-25;
  }

  .nav-tabs .nav-link {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    .medimo-icon {
      font-size: 13px;
    }
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color: $medimo-25 $medimo-25 #fff;
  }

  .nav-tabs {
    //border-bottom: none;
  }
}
