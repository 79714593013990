
  @import "/variables";
  svg.svg-inline--fa {
    font-size: 18px;
    vertical-align: -0.6em;
    margin-top: 7px; // Was overal wonky
    color: $medimo;
  }
  .disabled {
    svg.svg-inline--fa {
      color: $medimo; // De mindere opacity maakt hem al lichter
    }
  }
