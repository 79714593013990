
@import "/variables";

.data-tree {
  .data-tree-branch {
    min-width: 250px;
    white-space: nowrap;
    line-height: 20px;
  }

  .root {
    overflow-x: auto;
    > .data-tree-branch {
      margin-bottom: 10px;
    }
  }

  .data-tree-tag-value {
    font-weight: 600;
  }

  .data-tree-tag-close {
    // Dit moet om een rare span HTML rendering te corrigeren
    //left: -5px;
    position: relative;
  }
  .data-tree-data-name {
    color: $medimo;
  }
  .data-tree-attr {
    font-style: italic;

    .data-tree-attr-name {
      color: $red;
    }
    .data-tree-attr-value {
      color: $medimo-75;
    }
  }
}

