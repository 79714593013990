html {
  height: 100%;
  overflow-y: scroll !important;
}
body {
  width: 100%;
  height: 100%;
  color: $dark;

  // Needed for the Modal behaviour. This is built upon in the app.js watcher, that detects open/closing of modals
  // and fixes the body accordingly.
  position: relative;
  top: 0;
}

[v-cloak] {
  display: none;
}

.page-component {
  margin-bottom: 5px;
  padding: $page-component-padding;
  background-color: $white;
  border-radius: $border-radius;
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
  background: white;
  position: relative;

  &.no-border-radius-top {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
  }
  &.no-border-radius-bottom {
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }

  &.overview-page.transparent {
    max-width: $on-overview-page-max-width;
  }

  &.m-height {
    min-height: 518px;
  }
}

.medimo-tile-container {
  // Eerste row, eerste tile
  .medimo-tile-row:first-child {
    .medimo-tile-container:first-child > .medimo-tile {
      border-top-left-radius: 3px;
    }
    .medimo-tile-container:last-child > .medimo-tile {
      border-top-right-radius: 3px;
    }
  }
  .medimo-tile-row:last-child {
    .medimo-tile-container:first-child > .medimo-tile {
      border-bottom-left-radius: 3px;
    }
    .medimo-tile-container:last-child > .medimo-tile {
      border-bottom-right-radius: 3px;
    }
  }
  .medimo-tile-row.no-border-radius-top {
    .medimo-tile {
      border-top-right-radius: 0!important;
      border-top-left-radius: 0!important;
    }
  }
  .medimo-tile-row.no-border-radius-bottom {
    .medimo-tile {
      border-bottom-right-radius: 0!important;
      border-bottom-left-radius: 0!important;
    }
  }

  .medimo-tile {
    padding: $page-component-padding;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%);
    background: white;
    position: relative;
    border-radius: 0px;
  }
}

#main.page-component {
  padding: 0px;
}

.page-component.transparent {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
  border: none;

  .overview-link-container {
    box-shadow: $box-shadow;
  }
}

.router {
  .page-component {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .page-component:first-of-type {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  .page-component:last-of-type, {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

#extend_session_wrapper {
  z-index: 999999;
}


#page-container {
  position: relative;
  min-height: 100%;
}
.wrapper {
  padding-bottom: $footer-height;
  min-height: 100vh;
  margin-bottom: (0 - $footer-height);
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
}


#ce-logo {
  height: 14px;
  display: inline-block;
  position: relative;
  top: -2px;
}

#footer {

  text-align: center;
  width: 100%;

  a {
    color: $medimo;
  }

  .footer {
    height: $footer-height;
    z-index: 2;
  }
}

.postscript {
  font-size: 10px;
  color: $gray-500;
  margin-bottom: 1px;
}

.medimo-card {
  border-radius: $border-radius;
  background-color: $medimo-10;
  padding-right: calc($spacer / 2);
  padding-left: calc($spacer / 2);
  padding-top: calc($spacer / 2);
  padding-bottom: calc($spacer / 2);
}

.ajaxloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.3);
}

.ajaxloader img {
  z-index: 1000;
}

.ajaxloader div {
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
}

@include media-breakpoint-down(sm) {
  .page-component.transparent {
    padding: calc($page-component-padding-top / 2) calc($page-component-padding-right / 2) calc($page-component-padding-bottom / 2) calc($page-component-padding-left / 2)
  }
}

@include media-breakpoint-up(lg) {
  .container {
    max-width: $regular-max-width;
  }
}
