/* Loading Spinner */
.vs__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border-top: .9em solid rgba(100, 100, 100, .1);
  border-right: .9em solid rgba(100, 100, 100, .1);
  border-bottom: .9em solid rgba(100, 100, 100, .1);
  border-left: .9em solid rgba(60, 60, 60, .45);
  transform: translateZ(0);
  animation: vSelectSpinner 1.1s infinite linear;
  transition: opacity .1s;
}
.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

/* Loading Spinner States */
.vs--loading .vs__spinner {
  opacity: 1;
}
