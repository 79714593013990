/**
  For the MedimoDateTimePicker
  The default animations are obnoxious
 */
.medimo-datetime-picker {
  /** Slide animation **/
  .slide-enter-active, .slide-leave-active {
    opacity: 1;
    z-index: 998;
    transition: all 0.3s;
    transform: translateY(0);
  }

  .slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
    z-index: 998;
    transform: translateY(-20px);
  }

  /** Slide animation **/
  .slideinvert-enter-active, .slideinvert-leave-active {
    opacity: 1;
    z-index: 998;
    transition: all 0.3s;
    transform: translateY(0);
  }

  .slideinvert-enter, .slideinvert-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
    z-index: 998;
    transform: translateY(40px);
  }

  /** Slide next/prev animation  **/
  .slidenext-leave-active,
  .slidenext-enter-active, .slideprev-leave-active,
  .slideprev-enter-active {
    position: absolute;
    transition: all .3s;
  }

  .slidenext-enter, .slideprev-leave-to {
    transform: translateX(100%);
  }

  .slidenext-leave-to, .slideprev-enter {
    transform: translateX(-100%);
  }

  /** Slide vertical animation  **/
  .slidevnext-leave-active,
  .slidevnext-enter-active, .slidevprev-leave-active,
  .slidevprev-enter-active {
    position: absolute;
    transition: all .3s;
  }

  .slidevnext-enter, .slidevprev-leave-to {
    transform: translateY(100%);
    opacity: 0;
  }

  .slidevnext-leave-to, .slidevprev-enter {
    transform: translateY(-100%);
    opacity: 0;
  }

  @media screen and (max-width: 415px) {
    .slide-enter-active, .slide-leave-active {
      transition: all 0s;
    }
    /** Slide animation **/
    .slideinvert-enter-active, .slideinvert-leave-active {
      transition: all 0s;
    }
  }
}
