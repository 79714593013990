<template>
  <medimo-form-column column_classes="col-12">
    <div :class="'medimo-card mb-2 medication-guard-signal ' + (autoApprovable ? 'auto-approved' : '') + ' ' + (stillActive ? '' : 'is-inactive')">
      <div class="row" v-if="isMutationMedicationSignal && signal.type !== 'ExtraNotice'">
        <div class="col-1">
          <medimo-tooltip content="Niet gezien door voorschrijver" v-if="!signal.seenByPrescriber">
            <fa-icon icon="fa-regular fa-eye-slash" size="lg" class="text-medimo-50" />
          </medimo-tooltip>
          <medimo-tooltip v-else :content="
            signal.approvedByPrescriber
            ? 'Geaccordeeerd door ' + signal.prescriberUserName
            : 'Niet geaccordeeerd door ' + signal.prescriberUserName
          ">
            <medimo-checkbox extra_classes_container="prescriberWhenAuthorisingCheckbox" :disabled="true" :modelValue="!!signal.approvedByPrescriber" :just_the_checkbox="true"></medimo-checkbox>
          </medimo-tooltip>
        </div>
        <!-- Bij het nakijken staat dit vinkje links -->
        <div v-if="!examine" :class="'col-auto ml-auto'">
          <template v-if="stillActive">
            <medimo-checkbox v-if="signal.type !== 'ExtraNotice'" v-model="checkbox" :just_the_checkbox="true" class="d-flex"
                             extra_classes_container="my-auto authorizerCheckbox"></medimo-checkbox>
            <strong style="right: 10px; position: absolute; top: 0;"
                    v-if="(signal.requiresApproval === 1 || signal.requiresApproval === true)">*</strong>
          </template>
          <template v-else>
            <medimo-tooltip content="Eerder getoond signaal, nu niet meer van toepassing">
              <span class="p-1 pr-2">nvt</span>
            </medimo-tooltip>
          </template>
        </div>
        <!-- Examine Signal Checkboxes-->
        <template v-else>
          <div :class="'col-auto mr-auto'">
            <medimo-tooltip :content="
            signal.approvedByAuthorizer
               ? 'Geautoriseerd door ' + signal.authorizerUserName
               : 'Niet geautoriseerd door ' + signal.authorizerUserName
          ">
              <medimo-checkbox :disabled="true" v-if="signal.type !== 'ExtraNotice'" :modelValue="!!signal.approvedByAuthorizer" :just_the_checkbox="true" class="d-flex" extra_classes_container="my-auto approverWhenExaminingCheckbox"></medimo-checkbox>
            </medimo-tooltip>
          </div>
          <div class="col-auto" v-if="examine">
            <template v-if="stillActive">
              <medimo-checkbox v-if="signal.type !== 'ExtraNotice'" v-model="checkbox" :just_the_checkbox="true" class="d-flex" extra_classes_container="my-auto approverCheckbox"></medimo-checkbox>
              <strong style="right: 28px; position: absolute; top: 0;"
                      v-if="(signal.requiresApproval === 1 || signal.requiresApproval === true)">*</strong>
            </template>
            <template v-else>
              <medimo-tooltip content="Eerder getoond signaal, nu niet meer van toepassing">
                <span class="p-1 pr-2">nvt</span>
              </medimo-tooltip>
            </template>
          </div>
        </template>

      </div>

      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-12"><strong class="clickable" @click="openSignals">{{ signal.title }}</strong></div>
            <div class="col-12 my-2" v-if="isMutationMedicationSignal && signal.comments && signal.comments.length" v-for="comment in signal.comments">
              <fa-icon icon="fa-solid fa-sharp fa-messages" class="text-medimo-50 comments" /> {{ comment.createdByUserName }}: <em>"{{ comment.message }}"</em>
            </div>
            <div class="col-12">
              <div class="text-small">{{ signal.subTitle }}</div>
              <div class="text-small d-block" v-if="show_dosing && signal.triggerInfo">Dosering: {{ signal.triggerInfo.dosing ? signal.triggerInfo.dosing : 'geen' }}</div>
            </div>
          </div>
        </div>
        <div v-if="canPrescribePatient" class="col-auto d-flex">
          <medimo-checkbox v-if="signal.type !== 'ExtraNotice'" v-model="checkbox" :just_the_checkbox="true" class="d-flex" extra_classes_container="my-auto prescriberCheckbox"></medimo-checkbox>
          <strong style="right: 10px; position: absolute;" v-if="(signal.requiresApproval === 1 || signal.requiresApproval === true)">*</strong>
        </div>
      </div>

      <signal-tabs :forProvisionals="forProvisionals" :signal="signal" :schema_view="schema_view" :class="{opened_signal_tabs: isOpened}" v-show="isOpened"></signal-tabs>
    </div>
  </medimo-form-column>
</template>

<script>
import SignalTabs from './SignalTabs';
import MedimoCheckbox from '@/vue/components/general/form/base/MedimoCheckbox';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import MedimoTooltip from '@/vue/components/general/MedimoTooltip';

export default {
  components: {
    MedimoTooltip,
    SignalTabs,
    MedimoCheckbox,
    MedimoFormColumn,
  },

  props: {
    signal: {required: true},
    show_dosing: {default: false},
    schema_view: {default: false},
    hasInteractions: {default: true},
    isMutationMedicationSignal: {default: false},
    examine: {default: false},
    forProvisionals: {default: false},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    canPrescribePatient() {
      if (this.forProvisionals) {
        return false;
      }
      if (!this.patient) {
        return false;
      }
      return this.patient.authority.canPrescribePatient;
    },
    patientId() {
      return this.signal.patientId;
    },
    patient() {
      return this.$store.getters['patients/find'](this.patientId);
    },
    isOpened() {
      return this.$store.state.medication_guard_signals.signal_uid_opened === this.uid;
    },
    uid: function () {
      return this._.uid;
    },
    checkbox: {
      get() {
        return this.signal.approved === true;
      },
      set(value) {
        this.$store.commit('medication_guard_signals/update_approved', {
          'signal': this.signal,
          'approved': value,
          'schema_view': this.schema_view,
        });
      }
    },
    autoApprovable() {
      return this.examine && !this.signal.requiresApproval && this.signal.type !== 'ExtraNotice';
    },
    stillActive() {
      // Wanneer niet ge-set, of als hij true is, dan issie stillActive
      return (this.signal && typeof this.signal.stillActive === 'undefined') ? true : (this.signal && this.signal.stillActive === true) ? true : false;
    }
  },

  created() {
    //
  },

  mounted() {
    // We auto-aproven als er geen approval required is. Gelijk aan signal.blade.php
    // Heeft een kleine delay nodig om te voorkomen dat het te snel gebeurd
    setTimeout(() => {
      if (this.autoApprovable) {
        this.checkbox = true;
      }
    }, 500);
  },

  methods: {
    openSignals() {
      if (this.signal.type === 'ExtraNotice') {
        return false;
      }
      if (this.isOpened) {
        this.$store.commit('medication_guard_signals/set_open_signal_uid', 0);
      } else {
        this.$store.commit('medication_guard_signals/set_open_signal_uid', this.uid);
      }
    }
  },

  watch: {}
};
</script>
<style>
.medimo-card.medication-guard-signal.is-inactive {
  /* Medimo-5 */
  /*background-color: #F9FAFC;*/
  opacity: 0.65;
}
</style>
