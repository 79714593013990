
#schema-form {
  //.schema-form-thead, .schema-form-row {
  //  .medimo-form-column {
  //    padding-right: 2px;
  //    padding-left: 2px;
  //  }
  //
  //  > .medimo-form-column {
  //    &:first-of-type {
  //      padding-left: $grid-gutter-width / 2;
  //    }
  //
  //    &:last-of-type {
  //      padding-right: $grid-gutter-width / 2;
  //    }
  //  }
  //}
  .schema-dosing-input input, .extended-dosage-inputs input {
    padding: 5px 5px;
  }

  @include media-breakpoint-down(sm) {
    .manual-dosing-administration-input input {
      padding: 5px 5px;
    }

    .remove-row-column, .add-row-column {
      position: absolute;
      right: -25px;
      height: 100%;
    }

    .time-header-add-time-picker {
      .datetimepicker .datepicker {
        left: -52px;
      }
    }
  }

  .medimo-datetime-picker .date-time-picker {
    min-width: 100%;
  }
}

.time-label-button {
  display: inline-block;
  width: 10px;

  svg.svg-inline--fa.fa-xmark {
    position: relative;
    top: 4px;
    left: 2px;
  }

  .medimo-icon.icon-i-remove {
    top: 4px;
    left: -2px;
  }
}

@include media-breakpoint-down(sm) {
  .schema-form-row {
    .medimo-datetime-picker {
      .datetime-text-input {
        font-size: 0.7em;
        text-align: center;
        padding: 5px 4px 4px 4px !important;
        //padding: 8px 8px 7px 8px!important;
      }
    }
  }
}

.time-label {
  .time-label-button {
    position: absolute;

    .medimo-icon.icon-i-remove {
      top: 5px;
      left: -3px;
    }
  }

  .date-time-picker {
    span.clickable {
      font-size: 0.69em;
      font-weight: bold;
    }
  }
}

//}
