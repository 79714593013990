
.medimo-sort-button {
  position: relative;

  svg.svg-inline--fa {
    // We need this because the icons aren't to the edge of the space they inhabit, this aligns them properly
    left: 2px;
    opacity: 0.25;
    position: absolute;

    &.fa-caret-up {
      top: 0;
    }

    &.fa-caret-down {
      top: 6px;
    }

    &.active {
      opacity: 1;
    }
  }
}

.animate {
  animation-name: scaleAndFade;
  animation-duration: 0.35s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
}

@keyframes scaleAndFade {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
