@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?icv6ru') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?icv6ru') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?icv6ru##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-fa-"], [class*=" icon-fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fa-check-circle {
  &:before {
    content: $icon-fa-check-circle; 
  }
}
.icon-fa-info-circle {
  &:before {
    content: $icon-fa-info-circle; 
  }
}
.icon-fa-exclamation-triangle {
  &:before {
    content: $icon-fa-exclamation-triangle; 
  }
}
.icon-fa-briefcase {
  &:before {
    content: $icon-fa-briefcase; 
  }
}
.icon-fa-birthday-cake {
  &:before {
    content: $icon-fa-birthday-cake; 
  }
}
.icon-fa-download {
  &:before {
    content: $icon-fa-download; 
  }
}
.icon-fa-globe {
  &:before {
    content: $icon-fa-globe; 
  }
}
.icon-fa-map-marker {
  &:before {
    content: $icon-fa-map-marker; 
  }
}
.icon-fa-inbox {
  &:before {
    content: $icon-fa-inbox; 
  }
}
.icon-fa-flag {
  &:before {
    content: $icon-fa-flag; 
  }
}
.icon-fa-print {
  &:before {
    content: $icon-fa-print; 
  }
}
.icon-fa-exclamation-circle {
  &:before {
    content: $icon-fa-exclamation-circle; 
  }
}
.icon-fa-envelope {
  &:before {
    content: $icon-fa-envelope; 
  }
}
.icon-fa-rotate-left {
  &:before {
    content: $icon-fa-rotate-left; 
  }
}
.icon-fa-undo {
  &:before {
    content: $icon-fa-undo; 
  }
}
.icon-fa-lightbulb-o {
  &:before {
    content: $icon-fa-lightbulb-o; 
  }
}
.icon-fa-exclamation {
  &:before {
    content: $icon-fa-exclamation; 
  }
}
.icon-fa-graduation-cap {
  &:before {
    content: $icon-fa-graduation-cap; 
  }
}
.icon-fa-mortar-board {
  &:before {
    content: $icon-fa-mortar-board; 
  }
}
.icon-fa-database {
  &:before {
    content: $icon-fa-database; 
  }
}
.icon-fa-snowflake-o {
  &:before {
    content: $icon-fa-snowflake-o; 
  }
}
.icon-fa-list_alt {
  &:before {
    content: $icon-fa-list_alt; 
  }
}
.icon-fa-security {
  &:before {
    content: $icon-fa-security; 
  }
}
.icon-fa-trip_origin {
  &:before {
    content: $icon-fa-trip_origin; 
  }
}
.icon-fa-toll {
  &:before {
    content: $icon-fa-toll; 
  }
}
.icon-fa-animation {
  &:before {
    content: $icon-fa-animation; 
  }
}
.icon-fa-api {
  &:before {
    content: $icon-fa-api; 
  }
}
.icon-fa-medical_services {
  &:before {
    content: $icon-fa-medical_services; 
  }
}
.icon-fa-coronavirus {
  &:before {
    content: $icon-fa-coronavirus; 
  }
}
.icon-fa-display {
  &:before {
    content: $icon-fa-display; 
  }
}
.icon-fa-search {
  &:before {
    content: $icon-fa-search; 
  }
}
.icon-fa-zoom-in {
  &:before {
    content: $icon-fa-zoom-in; 
  }
}
.icon-fa-zoom-out {
  &:before {
    content: $icon-fa-zoom-out; 
  }
}

