/* Clear Button */

.vs__clear {
  fill: $vs-controls-color;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px;
}
