.dispense-date-picker, .dispense-date-picker-read-only {

  .date-block {
    &.transparent {
      opacity: 0.25;
    }

    .content {
      vertical-align: middle;
    }

    &.in-schema:hover {
      //box-shadow: $box-shadow;
      cursor: pointer;
    }
  }

  .week {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    /* More style: */
    //height: 300px;
    //background-color: #888;
  }

}

.dispense-date-picker-read-only {
  .date-block {
    &.transparent {
      opacity: 0.5!important;
    }
  }
}
