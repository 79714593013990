@import 'medimo/fonts/_open_sans';
@import 'variables';
// Mixins
@import 'medimo/mixins/_dropdown';
@import 'medimo/mixins/_notification';

// This one resets the "User Agent Stylesheet" crap
@import 'medimo/_normalize';

/* Bootstrap */
@import 'bootstrap/bootstrap';

@import 'medimo/icons/medimo_icons';
@import 'medimo/icons/extra/scss/nucleo';
@import 'medimo/icons/extra/scss/overrides';
@import 'medimo/icons/fa-extra/style';

@import 'medimo/_animate';

@import 'medimo/_v2_overrides'; // <- Override for the new design on the old website.
//@import 'medimo/_v3_overrides';

@import 'medimo/_accessibility';
@import 'medimo/_environment_settings';
@import 'medimo/_layout';
@import 'medimo/_colors';
@import 'medimo/_modal';
@import 'medimo/_breadcrumb';
@import 'medimo/_alert';
@import 'medimo/_notification';
@import 'medimo/_buttons';
@import 'medimo/_form';
@import 'medimo/_helpers';
@import 'medimo/_loaders';
@import 'medimo/_text';
@import 'medimo/_images';
@import 'medimo/_rows';
@import 'medimo/_vue';
@import 'medimo/_tables';
@import 'medimo/_nav';
@import 'medimo/_badge';
@import 'medimo/_input-group';
@import 'medimo/_tabs';
@import 'medimo/_charts';
@import 'medimo/_print';
@import 'medimo/_icons';

/** Pages **/
@import 'medimo/pages/_login';
@import 'medimo/pages/_status';
@import 'medimo/pages/_overview';
@import 'medimo/pages/tdr/dailylist';
@import 'medimo/pages/config/authority/users';
@import 'medimo/pages/mutations/mutations';

/** Components **/
@import 'medimo/components/status/dispense/dispense-date-picker';
@import 'medimo/forms/vue-select2';

/** Utilities (same directory structure as bootstrap) **/
@import 'medimo/utility/_display';
// Make sure this stays last
@import 'medimo/_ie11_overrides';


body {
  background-color: $background-color-base;
  font-family: $font-family-base;
  font-size: 14px;
}

* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  color-adjust: exact !important; /*Firefox*/
}
