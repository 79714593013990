@mixin alert-variant($background, $border, $color) {
  color: white;
  background-color: $color;
  border-color: $border;

  hr {
    border-top-color: darken($border, 5%);
  }

  .alert-link, a {
    color: darken($color, 10%);
    text-decoration: underline;
  }

  .body {
    color: lighten($color, 35%);
  }
}
