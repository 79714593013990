.alert-container {
  position: fixed;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  top: 65px;

  & .canvas {
    background-color: white;
    border-radius: $border-radius;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  }

  & .sticky-element {
    pointer-events: none;

    .medimo-notification, .medimo-alert {
      pointer-events: auto;
    }
  }
}

.alert {
  min-height: 40px;
  display: flex;
  //justify-content: center;
  align-items: center;

  a {
    color: white;
    text-decoration: underline;
  }
}

.ui-state-error.ui-corner-all {

  //background-color: transparent;
  background-color: $medimo-10;
  border-width: 0;
  border-left-width: 5px;
  border-color: $red;

}

a.alert:hover {
  // Dont underline alerts
  text-decoration: none;
}


/*
 |--------------------------------------------------------------------------
 | The Session Alert Settings
 |--------------------------------------------------------------------------
 |
 |
 |
 |
 */
#extend_session_wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(0, 0, 0, .4);

  #extend_session {
    width: 400px;
    height: inherit;
    position: absolute;
    margin-left: -200px;
    margin-top: -80px;
    top: 50%;
    left: 50%;
    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 15px;
    text-align: center;
    line-height: $line-height-base;

    #session_time_remaining {
      background-color: $medimo-10;
      border-radius: $border-radius;
      color: $red;
      font-weight: $font-weight-bold;
      padding: calc($spacer / 8) calc($spacer / 4);
    }
  }

  #keepalive-notification-button:hover {
    cursor: pointer;
  }
}
