/*\
 * Restore Bootstrap 3 "hidden" utility classes.
\*/

/* Breakpoint XS */
@media (max-width: (map_get($grid-breakpoints, 'sm') - 1)) {
  .hidden-xs-down, .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, .hidden-xxl-down,
  .hidden-xs-up,
  .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl, .hidden-unless-xxl {
    display: none !important;
  }

}

/* Breakpoint SM */
@media (min-width: map_get($grid-breakpoints, 'sm')) and (max-width: (map_get($grid-breakpoints, 'md') - 1)) {
  .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, .hidden-xxl-down,
  .hidden-xs-up, .hidden-sm-up,
  .hidden-unless-xs, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl, .hidden-unless-xxl {
    display: none !important;
  }
}

/* Breakpoint MD */
@media (min-width: map_get($grid-breakpoints, 'md')) and (max-width: (map_get($grid-breakpoints, 'lg') - 1)) {
  .hidden-md-down, .hidden-lg-down, .hidden-xl-down, .hidden-xxl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-lg, .hidden-unless-xl, .hidden-unless-xxl {
    display: none !important;
  }
}

/* Breakpoint LG */
@media (min-width: map_get($grid-breakpoints, 'lg')) and (max-width: (map_get($grid-breakpoints, 'xl') - 1)) {
  .hidden-lg-down, .hidden-xl-down, .hidden-xxl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-xl, .hidden-unless-xxl {
    display: none !important;
  }
}

/* Breakpoint XL */
@media (min-width: map_get($grid-breakpoints, 'xl')) and (max-width: (map_get($grid-breakpoints, 'xxl') - 1)) {
  .hidden-xl-down, .hidden-xxl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xxl {
    display: none !important;
  }
}

/* Breakpoint XXL */
@media (min-width: map_get($grid-breakpoints, 'xxl')) {
  .hidden-xxl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up, .hidden-xxl-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl {
    display: none !important;
  }
}
