// Notification mixin
@mixin dropdown-item-variant($type, $color) {
  .dropdown-item.dropdown-item-#{$type} {
      background-color: white;

      &:hover, .dropdown-item:hover {
        background-color: $color;
        color: white;
      }
  }
}
