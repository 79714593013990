body {
  font-family: $font-family-base;
  line-height: $line-height-base;
  font-size: 14px;
}

a {
  color: $link-color;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: $dark;
}

.capitalize {
  text-transform: capitalize !important;
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}

.text-small {
  font-size: $small-font-size !important;
}

@include media-breakpoint-down(sm) {
  .text-small-sm, .text-small-sm div, .text-small-sm > div, .text-small-sm span {
    font-size: $small-font-size !important;
  }
}

abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, main, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  font-size: $font-size-base;
}

code {
  color: $red;
  padding: calc($spacer / 4);
  background-color: $medimo-10;
  border-radius: $border-radius;
}

hr.lg {
  border-width: 10px;
}

hr.md {
  border-width: 3px;
}

.medimo-icon {
  //vertical-align: middle;
  /* The top one looks better */
  position: relative;
  top: 2px;
  font-size: 16px;
  margin-right: calc($spacer / 4)
}

svg.svg-inline--fa {
  margin-right: calc($spacer / 4);
  &.fa-lg {
    font-size: 1.334em;
    vertical-align: -0.2168em;
  }
}

.disabled {
  // Override the text color in disabled situations
  @each $color, $value in $theme-colors {
    &.text-#{$color} {
      color: color-yiq($value) !important;
    }
  }
}

// This one forces a regular cursor when you need it
.auto-cursor:hover {
  cursor: auto !important;
}

.disabled {
  //pointer-events: inherit!important;
  &:hover {
    cursor: not-allowed !important;
    //color: inherit!important;
  }
}

.text-overflow {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
