
  @import "/variables";
  @import '/bootstrap/functions';
  @import '/bootstrap/variables';

.page-tabs {
  ul.nav-tabs {
    li.nav-item {
      margin-right: 0.2em;

      .nav-link {
        background: $medimo-5;
        border-color: $medimo-10;
        border-bottom-color: $gray-300;

        &.active {
          background: white;
          border-color: $medimo-75;
          border-bottom-color: white;
          cursor: default;
        }
      }
    }

    .badge {
      height: 17px;
      line-height: 9px;
    }
  }

  .tab-content {
    border: none;

    // Niet bij pageBlock een border om de tabs content - links en rechts
    border-bottom-width: 0;
    border-right-width: 0;
    border-left-width: 0;
  }
}
