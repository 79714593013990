
  .json.data-tree-branch {
    display: block !important;
    .toggle-icon {
      margin-right: 0.5em;
    }
    // Om de weergave consistent te krijgen en houden zetten we hem hier vast
    // Door hem vervolgens X scrollable te maken is het alsnog overzichtelijk.
    .dataRow {
      width: 350px;
      overflow-x: visible;
      white-space: nowrap;

      div {
        display: inline;
      }

      .key {
        width: 100px;
      }
      .value {
        width: 250px;

        a {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }
