$icomoon-font-family: "fa-icons" !default;
$icomoon-font-path: "./fonts" !default;

$icon-fa-check-circle: "\e90f";
$icon-fa-info-circle: "\e90e";
$icon-fa-exclamation-triangle: "\e90d";
$icon-fa-briefcase: "\e90c";
$icon-fa-birthday-cake: "\e90b";
$icon-fa-download: "\e90a";
$icon-fa-globe: "\e909";
$icon-fa-map-marker: "\e908";
$icon-fa-inbox: "\f01c";
$icon-fa-flag: "\f024";
$icon-fa-print: "\f02f";
$icon-fa-exclamation-circle: "\f06a";
$icon-fa-envelope: "\f0e0";
$icon-fa-rotate-left: "\f0e2";
$icon-fa-undo: "\f0e2";
$icon-fa-lightbulb-o: "\f0eb";
$icon-fa-exclamation: "\f12a";
$icon-fa-graduation-cap: "\f19d";
$icon-fa-mortar-board: "\f19d";
$icon-fa-database: "\f1c0";
$icon-fa-snowflake-o: "\f2dc";
$icon-fa-list_alt: "\e906";
$icon-fa-security: "\e905";
$icon-fa-trip_origin: "\e900";
$icon-fa-toll: "\e901";
$icon-fa-animation: "\e902";
$icon-fa-api: "\e903";
$icon-fa-medical_services: "\e907";
$icon-fa-coronavirus: "\e904";
$icon-fa-display: "\e956";
$icon-fa-search: "\e986";
$icon-fa-zoom-in: "\e987";
$icon-fa-zoom-out: "\e988";

