.tdrdaily, .tdr-swipeable {
  //.date-row {
  //  border-radius: 3px;
  //  border-left: #70A3CF 12px solid;
  //}

  .patient-tile {
    background-color: white;
    border-radius: $border-radius;
    //padding: $spacer;

    img {
      width: 100%;
      border-radius: $border-radius;
      //padding: 25px 25px;
      //margin-bottom: -13px;
      //margin-top: -13px;
    }

    h4 {
      color: $medimo;
      //margin: 0;
    }
  }
  .patient-tile:hover {
    box-shadow: $box-shadow;
  }

  .patient-tile-link {
    &:hover {
      text-decoration: none;
    }
  }

  h1,h2,h3 {
    color: $medimo;
  }
}

.tdrdaily, .dispensedaily {
  .patient-tile {
    padding: $spacer $spacer $spacer $spacer;

    .icon-fa-flag {
      font-size: 2em;
    }
  }
}

.tdr-swipeable {
  .patient-tile {
    padding: $spacer $spacer $spacer $spacer;

    img {
      width: 97%;
    }

    .icon-fa-flag {
      font-size: 3em;
    }
  }
}
