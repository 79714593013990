body.high-contrast {
  &.not-logged-in {
    .ce {
      filter: brightness(0) invert(1);
    }

    #footer {
      color: white;
      a {
        color: white;
      }
    }
  }

  &.not-logged-in.acceptance {
    background-color: lighten($green, 10%);
  }

  &.not-logged-in.development {
    background-color: lighten($red, 10%);
  }

  &.not-logged-in.production {
    background-color: lighten($medimo, 10%);
  }

  .accessibility:not(#top-nav .accessibility) {
    color: white;
    &:hover {
      color: $medimo-10;
    }
  }

  // _badge.scss
  .badge-medimo-50, .badge-warning {
    color: black;
  }

  // _notification.scss
  .notification-danger {
    color: #C53E35;
  }

  // _text.scss
  .text-muted {
    color: #65748B !important;
  }

  // _text-emphasis.scss
  .text-medimo-25 {
    color: #65748B !important;
  }

  .text-danger {
    color: #C53E35 !important;
  }

  // _buttons.scss
  .btn.btn-link {
    &.transparent {
      color: #65748B;
    }
  }

  // bootstrap/_pagination.scss
  .page-item {
    &.disabled .page-link {
      color: #65748B;
    }
  }

  // _tables.scss
  .medimo-table {
    .pagination {
      .page-item.info {
        .page-link {
          color: #65748B;
        }
      }
    }
  }
}
