
/** Nodig om de styles te counteren voor de advanced filter modal, aangezien deze hier nested is in NavSearch **/
#top-nav li.nav-item .nav-search-report-link .medimodal .btn {
  padding: 0.3rem 0.75rem;
}

#nav-search .list-group .nav-search-report-link .medimodal {
  .btn-primary, .btn-medimo, .btn:hover {
    color: white;
  }
}

#nav-search .nav-search-report-link .medimodal {
  .medimo-vue-select2 input {
    border: none;
  }

  input {
    border-radius: 3px;
    width: 100%;
    padding-left: 4px;
    min-height: 0;
  }
  svg.svg-inline--fa {
    opacity: 1;
  }
}
