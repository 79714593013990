$vs-colors: (
        lightest: rgba(60, 60, 60, 0.26),
        light: rgba(60, 60, 60, 0.5),
        dark: #333,
        darkest: rgba(0, 0, 0, .15),
) !default;

//  Global Component Variables
$vs-component-line-height: 20px;
$vs-component-placeholder-color: inherit !default;

//  Active State
$vs-state-active-bg: $medimo;
$vs-state-active-color: #fff !default;

//  Disabled State
$vs-state-disabled-bg: $medimo-5;
$vs-state-disabled-color: map_get($vs-colors, 'light') !default;
$vs-state-disabled-controls-color: map_get($vs-colors, 'light') !default;
$vs-state-disabled-cursor: not-allowed !default;

//  Borders
$vs-border-width: 1px !default;
$vs-border-style: solid !default;
$vs-border-radius: 4px !default;
$vs-border-color: map_get($vs-colors, 'lightest') !default;

//  Component Controls: Clear, Open Indicator
$vs-controls-color: map_get($vs-colors, 'light') !default;
$vs-controls-size: 1 !default;
$vs-controls-deselect-text-shadow: 0 1px 0 #fff;

//  Selected
$vs-selected-bg: #f0f0f0 !default;
$vs-selected-border-color: $vs-border-color !default;
$vs-selected-border-style: $vs-border-style !default;
$vs-selected-border-width: $vs-border-width !default;

//  Dropdown
$vs-dropdown-z-index: 10000 !default;
$vs-dropdown-min-width: 160px !default;
$vs-dropdown-max-height: 350px !default;
$vs-dropdown-box-shadow: 0px 3px 6px 0px map_get($vs-colors, 'darkest') !default;
$vs-dropdown-bg: #fff !default;

@import "resources/assets/js/vue/components/general/form/base/vue-select2/vue3-src/scss/vue-select";

//.medimo-vue-select2 {
//  border: $input-border-width solid $input-border-color;
//  height: $input-height;
//}

// Omdat de MedimoVueSelect2 _buiten_ het component om gerenderd wordt, moeten we die als root nemen
// voor de opties.
.vs__dropdown-menu {
  border-top-right-radius: 4px;

  .vs__dropdown-option {
    padding-left: 15px;
    padding: 6px;

    &:hover {
      background-color: $medimo;
      color: white;
    }

    &.vs__dropdown-option--disabled:hover {
      background-color: white;
    }

    &.vs__dropdown-option--selected {
      background-color: $medimo-75;
      color: white;
    }
  }

  & {
    padding-bottom: 0;
  }
}

.medimo-vue-select2 {
  .vs__search {
    z-index: inherit;
  }

  .medimo-tooltip {
    right: 26px;
    top: 6px;
    position: absolute;
  }
}

.vue-select2, .medimo-vue-select2 {
  .vs--disabled {
    .vs__dropdown-toggle {
      background-color: $medimo-5;
    }
  }

  .vs__actions {
    padding: 4px 0 0 3px;
    position: absolute;
    right: 5px;
    top: 4px;

    .vs__clear {
      margin-right: 0;
      right: 19px;
      position: absolute;
    }
  }

  &.has-help-text {
    .vs__actions {
      .vs__clear {
        right: 39px;
      }
    }
  }

  .vs__dropdown-toggle {
    min-height: 32px;
    background-color: white;
    border: $border-style;

    border: 1px solid $medimo-10;

    .vs__deselect {
      .icon-i-remove {
        top: 1px;
      }
    }
  }

  .multiple {
    .form-control {
      height: inherit;
    }
  }

  .medimo-icon {
    color: $medimo;
  }

  input.vs__search::placeholder {
    color: $medimo-50;
  }

  &.closed.has-selection .vs__search {
    // Rare bug in vue-select waarbij de search box zichtbaar blijft maar de opmaak verknalt
    // Enkel alleen wanneer er een waarde geselecteerd is
    display:none;
  }

  .vs__search, .single .vs__selected {
    //margin: 4px 0 0 0;
    padding: 0;
    padding-left: 7px;
    // Deze marge is nodig om er voor te zorgen dat de help_text goed hovert en zichtbaar is
    margin-right: 20px;

    // width: 100%;
    display: block;
  }

  &.has-selection.single .vs__selected {
    height: 22px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ltr;
    word-break: break-all;
  }

  .vs__selected {
    white-space: nowrap;
  }

  .vs--multiple {
    .vs__selected {
      max-width: 230px; // Deze kun je in een component overschrijven om hem breder te maken
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      direction: ltr;
      position: relative;
      padding-left: 20px;
    }

    .vs__deselect {
      position: absolute;
      left: 0px;
      top: 5px;
    }
  }

  &.closed {
    line-height: $input-height;
  }
}

.vs__dropdown-menu  .vs__dropdown-option.vs__dropdown-option--disabled {
    padding-left: 5px;

  & > .group {
    color: black!important;
    font-weight: bold;
  }
}

.vs__dropdown-menu {
  // De modals hebben altijd een z-index van in de 1000-tallen
  // op deze manier is de dropdown altijd over alles zichtbaar.
  z-index: 10000;

  .vs__no-options {
    margin: 4px;
    padding-bottom: 5px;
  }
}

.vs__spinner {
  width: 20px;
  height: 20px;
}

form.medimo-form {
  .medimo-vue-select2 span.medimo-tooltip {
    right: 2em;
    z-index: inherit;
  }

  .medimo-vue-select2.has-value.erasable span.medimo-tooltip {
    right: 1.62em;
  }
  .medimo-vue-select2.is-invalid {
    .vs__dropdown-toggle {
      border: 1px solid $danger;
    }
  }
}
