/*------------------------
	icons
-------------------------*/
@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

$icon-key: unicode(ea05);
$icon-b-chat: unicode(ea08);
$icon-single-02: unicode(ea0b);
$icon-todo: unicode(ea12);
$icon-time-clock: unicode(ea13);
$icon-c-warning: unicode(ea15);
$icon-i-add: unicode(ea16);
$icon-i-remove: unicode(ea17);
$icon-recipe: unicode(ea18);
$icon-progress: unicode(ea2a);
$icon-settings-gear: unicode(ea2e);
$icon-c-info: unicode(ea3a);
$icon-heartbeat: unicode(ea3e);
$icon-multiple-19: unicode(ea40);
$icon-shape-square: unicode(ea41);
$icon-networking: unicode(ea42);
$icon-apartment: unicode(ea43);

.icon-key::before {
  content: $icon-key;
}

.icon-b-chat::before {
  content: $icon-b-chat;
}

.icon-single-02::before {
  content: $icon-single-02;
}

.icon-todo::before {
  content: $icon-todo;
}

.icon-time-clock::before {
  content: $icon-time-clock;
}

.icon-c-warning::before {
  content: $icon-c-warning;
}

.icon-i-add::before {
  content: $icon-i-add;
}

.icon-i-remove::before {
  content: $icon-i-remove;
}

.icon-recipe::before {
  content: $icon-recipe;
}

.icon-progress::before {
  content: $icon-progress;
}

.icon-settings-gear::before {
  content: $icon-settings-gear;
}

.icon-c-info::before {
  content: $icon-c-info;
}

.icon-heartbeat::before {
  content: $icon-heartbeat;
}

.icon-multiple-19::before {
  content: $icon-multiple-19;
}

.icon-shape-square::before {
  content: $icon-shape-square;
}

.icon-networking::before {
  content: $icon-networking;
}

.icon-apartment::before {
  content: $icon-apartment;
}
