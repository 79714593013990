$transition-timing-function: cubic-bezier(1.0, 0.5, 0.8, 1.0);
$transition-duration: .15s;

/* KeyFrames */
@-webkit-keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Dropdown Default Transition */
.vs__fade-enter-active,
.vs__fade-leave-active {
  pointer-events: none;
  transition: opacity $transition-duration $transition-timing-function;
}
.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}
