.btn {
  height: $btn-height;
  line-height: $line-height-base-px;

  .medimo-icon {
    top: 3px;
  }

  &.btn-sm {
    height: 26px;
    line-height: 16px;

    .medimo-icon {
      font-size: 12px;
      top:2px;
      margin-right:0;
    }
  }
  &[class*="btn-outline-"] {
    // Needs this because the border goes from 2px to 1px
    line-height: $line-height-base-px + 2;
  }
}
#footernav svg {
  position: relative;
  left: 1px;
  margin-left: 0;
}

.medimo-filter-button {
  line-height: $line-height-base;
}

.medimo-copy-button {
  .medimo-icon-extra.icon-copy {
    top: 4px;
  }
}

@each $color, $value in $theme-colors {
  // Override the text color in disabled situations
  .btn.btn-#{$color}.disabled {
    //color: color-yiq($value) !important;
    // Voor nu even altijd wit if disabled ipv dynamisch
    color: white !important;
    cursor: not-allowed;
  }
  // Override the text color in disabled situations
  .btn.btn-#{$color}.disabled.no-blinking {
    background-color: $value;
    border-color: $value;
    opacity: 1;
  }
}

.btn.not-allowed:hover {
  cursor: not-allowed;
}

//.dropdown {
//  a.btn.btn-primary, a.btn.btn-medimo {
//    border-right: 1px solid white;
//  }
//}

.btn.home-medimo-link {
  .medimo-icon {
    margin-right: 0;
  }
}

.btn.btn-link {
  //text-transform: uppercase;
  color: $button-color;
  //font-weight: 600;
  font-size: $font-size-base;
  //padding: 0 10px;

  .fa {
    color: $button-color;
  }

  &:hover {
    cursor: pointer;
  }

  &.transparent {
    //opacity: 0.25;
    border-color: $medimo-25;
    color: $medimo-50;

    &:hover {
      border-color: $medimo;
      color: $medimo;
      //opacity: 1.0;
    }
  }

  & {
    // These buttons look weird when the line-height is different from the btn height.
    line-height: $btn-height;
  }

  &.slim {
    line-height: inherit;
    height: inherit;
  }

  i.medimo-icon, i.medimo-icon-extra, i.fa-icons  {
    &.medimo-icon-sm {
      font-size: 12px;
      top: 2px;
    }
    & {
      font-size: 16px;
      top: 3px;
      position:relative;
    }
  }
  i.fa-icons {
    font-size: 18px;
  }
}

i.medimo-icon .badge, svg.svg-inline--fa + .badge {
    padding: 0.35em;
    font-size: 10px;
    position: absolute;
    top: -12px;
    left: 11px;
    border-radius: 16px;
    min-width: 16px;
}

.bell {
  //width: 2rem;
  &:hover, &:focus, &.shake {
    animation: bellshake .5s cubic-bezier(.36, .07, .19, .97) both;
    backface-visibility: hidden;
    transform-origin: top right;
  }

  &.shake:hover, &.shake {
    animation-iteration-count: infinite;
  }
}

#switch-table-view {
  &.shake {
    animation: jump 1s ease-in-out both;
    backface-visibility: hidden;
    transform-origin: center;
  }

  &.shake {
    animation-iteration-count: infinite;
  }
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes jump {
  0% {
    transform: scale(1);
    //box-shadow: 0 1px 2px rgba(0,0,0,.15);
  }
  50% {
    transform: scale(1.35);
    //box-shadow: 0 4px 20px rgba(0,0,0,.1);
  }
  100% {
    transform: scale(1);
    //box-shadow: 0 4px 20px rgba(0,0,0,.1);
  }
}

.btn.btn-medimo-15 {
  color: $medimo;
}

.btn-success {
  color: white;
}

.btn-outline-success:hover {
  color: white;
}

.btn-primary.btn-outline-primary {
    background-color: rgba(0,0,0,0);
}

.btn.btn-secondary.disabled, .btn.btn-secondary[disabled] {
  color: $white;
}

.btn.btn-primary:hover, .btn.btn-medimo:hover, .btn.btn-primary:not(:disabled):not(.disabled):active, .btn.btn-medimo:not(:disabled):not(.disabled):active, .btn.btn-bright-blue:hover, .btn.btn-bright-blue:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle, .show > .btn-medimo.dropdown-toggle {
  background-color: $button-hover-color;
  border-color: $button-hover-color;
}


.btn.btn-outline-medimo, .btn.btn-outline.primary {
  color: $button-color;
}
.btn.btn-outline-medimo:hover {
  color: white;
}

/*
 |--------------------------------------------------------------------------
 | Dropdown button
 |--------------------------------------------------------------------------
 |
 |  Used by the MedimoDropdownButton and MedimoDropdownItem
 |
 |
 */
.dropdown-toggle-split, .dropdown-toggle-split:hover, .dropdown-toggle-split:focus, .dropdown-toggle-split:active {
  //border: none;
  //border-left: 1px white solid;
  min-width: 36px;
  cursor: pointer;
  outline: none;
}

.medimo.dropdown {
  display: inline-block;

  // These make sure they're full-width on phone screens
  @include media-breakpoint-down('sm') {
    display: block;
    .btn-group {
      width: 100%;

      :first-child {
        width: 100%;
      }
    }
  }

  .dropdown-toggle::after {
    vertical-align: 0.15em
  }

  &.slim {
    .btn {
      line-height: inherit;
      height: inherit;
    }
  }

  div.dropdown-menu {
    display: block;
    background-color: white;
    padding: 0 0; // We leave this to the first and last item
    border: none;
    box-shadow: $box-shadow;

    // Should be safe to comment out. Remove when all is well after release on 23-06-2020
    //@include media-breakpoint-down('sm') {
    //  width: 100%;
    //}

    @each $color, $value in $theme-colors {
      @include dropdown-item-variant("#{$color}", $value);
    }

    .dropdown-item {
      color: $menu-color;
      position: relative;
      //margin-top: $spacer / 4;
      padding: calc($spacer / 2) $spacer calc($spacer / 2) $spacer;

      &.disabled {
        opacity: 0.5;
      }

      hr {
        border-bottom: 1px solid $medimo-10;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        //top: 4px;
      }
    }
  }
}

/*
 |--------------------------------------------------------------------------
 | Breakpoints
 |--------------------------------------------------------------------------
 |
 |
 |
 |
 */
@include media-breakpoint-down(sm) {

  .btn {
    margin-bottom: calc($spacer /4);
  }

  .btn-sm-block, .home-medimo-link {
    width: 100%;
  }

  // This automatically makes it a block button on SM
  .medimo-dropdown-button {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: flex !important;

    a.btn {
      display: block;
      width: 100%;
    }
  }
}
