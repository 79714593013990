//  Open Indicator

//  The open indicator appears as a down facing
//  caret on the right side of the select.

$transition-timing-function: cubic-bezier(1.000, -0.115, 0.975, 0.855);
$transition-duration: 150ms;

$open-indicator-color: $vs-controls-color;
$open-indicator-size: $vs-controls-size;

.vs__open-indicator {
  fill: $open-indicator-color;
  transform: scale($open-indicator-size);
  transition: transform $transition-duration $transition-timing-function;
  transition-timing-function: $transition-timing-function;
}

//  Open State

.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale($open-indicator-size);
}

//  Loading State

.vs--loading .vs__open-indicator {
  opacity: 0;
}
