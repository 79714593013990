
// In een modal zit er al padding onder de modal-body, dus mag hij minder hoog
.medimodal {
  .medimo-table.m-height {
    min-height: 518px;
  }
}

.medimo-table {

  &.m-height {
    min-height: 530px;

    .table {
      min-height: 402px;

      .tbody {
        min-height: $table-tr-height * 10;
      }
    }
  }

  &.no-m-height {
    min-height: 0;
    .table {
      min-height: 0;
      .tbody {
        min-height: 0;
      }
    }
  }

  /**
      Hide the tbody if there are now rows.
      Because the thead and tbody are in a slot, and we don't want to have to define
      this for every table, this works nice and clean.
   */
  .medimo-table-content.no-rows {
    .tbody {
      display: none;
    }
  }

  .medimo-tr.tr.bg-danger {
    > .medimo-td.td {
      color: #e5ebf2 !important;
    }
  }

  //.table {
  //  margin-bottom: 0;
  //}

  .pagination {
    .page-item.info {
      .page-link {
        color: #999; // Same as in the current Datatables
      }
    }

    .page-item.info .page-link {
      background-color: white;
      border: none;

      &:hover {
        color: $medimo;
        text-decoration: none;
        background-color: inherit;
      }
    }

    .page-item.active .page-link {
      background-color: $medimo;
    }

    .page-item.next .page-link, .page-item.previous .page-link {
      background-color: white;
      font-weight: bold;

      &:hover {
        background-color: $medimo;
        color: white;
      }
    }

    .page-item.disabled .page-link {
      background-color: white;
    }

    .page-link {
      &:hover {
        background-color: $medimo;
        color: white;
      }

      & {
        margin: 0 calc($spacer / 10);
        border: none;
        background-color: $medimo-10;
        border-radius: $border-radius;
      }

      .medimo-icon {
        font-size: inherit;
      }
    }
  }

  #filter-input {
    padding-left: 0;
  }

  .input-group-prepend .input-group-text {
    padding: $input-btn-padding-y calc($input-btn-padding-x / 2);
  }

  .advanced-filter-button {
    border-radius: $border-radius;
    //border: 1px solid $medimo;
    height: $input-height;
    line-height: $input-height - 5;
  }

  .quick-filter-button, .quick-filter-group {
    border-radius: $border-radius;
    border: 1px solid $medimo;
    line-height: $input-height - 2;

    .medimo-icon-extra {
      position: relative;
      font-size: $font-size-base;
      top: 2px;
    }

    &:hover {
      text-decoration: none;
    }
  }
  .quick-filter-button {
    height: $input-height;
  }

  .quick-filter-group {
    line-height: $input-height - 3!important; // ivm borders
    // Deze wordt ingesteld als er geen actieve quick-filters in een group zijn
    // Identiek aan de .btn.btn-link.transparent van de quick filter button
    &.inactive {
      border: 1px solid $medimo-25;
    }
    .quick-filter-button {
      height: 29px;
      line-height: $input-height - 5;
      border: none;
      &:first-of-type::before {
        content: none;
      }
      &::before {
        content: "|";
        position: relative;
        opacity: 0.5;
        top: -2px;
        right: 3px;
        margin-left: -2px;
      }
    }
  }

  // Dit is het sterretje waarmee je de query / quickfilter / pagina combo op kunt slaan
  .favorite-setting {
    color: $medimo-75;
    position:absolute;
    right:14px;
    top:7px;
    font-size: 12px;
  }
  &.medimo-data-table {
    form.medimo-form.datatable-query-form .medimo-input .medimo-tooltip {
      right: 25px; // Nodig om het ? icoon goed naast het sterretje van settings opslaan te krijgen
    }
  }
}

div.table {
  width: 100%;

  .th, .td {
    padding: $table-cell-padding;
  }

  .thead {
    border-bottom: 2px solid $shaded-blue;
  }

  .tbody {
    > .tr {
      min-height: $table-tr-height;
      border-bottom: 1px solid $table-border-color;
      line-height: 1.75;
      color: $primary;

      > .td {
        &:hover {
          cursor: pointer;
        }
      }

      &.disabled {
        color: black;
        > .td {
          &:hover {
            cursor: default;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        text-decoration: underline;
      }

      // Voor als je geen navigatie oid op de hele regel hebt, maar wel de kleuren "normaal" wil houden
      &.inactive:hover {
        text-decoration: none;
        cursor: default;

        > a:hover {
          cursor: pointer;
        }
      }
      &.normal:hover {
        cursor: default;
        > a:hover {
          cursor: pointer;
        }
      }
    }
  }

  // Different Styles for things in a disabled table
  &.disabled .tbody {
    .td {
      color: black;
    }

    > .tr:hover {
      text-decoration: none;
      cursor: not-allowed;
    }
  }

  .thead {
    .th {
      color: $dark;
      font-weight: 600;
      line-height: calc($line-height-base / 3) * 4;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .row {
      border: none;
    }

    //border-bottom: 2px solid $medimo-75;
  }

  .tbody > .row {
    margin-right: 0;
    margin-left: 0;
  }

}

table {
  margin-top: 0;
}

.table.table-striped {
  .tbody > .tr:nth-child(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}

.table th {
  border-top: none;
  color: $black;
}

.table thead th {
  border-bottom-color: $shaded-blue !important;
}

.table.modal-details-table {
  .b-table-details {
    td {
      padding: 0;
    }
  }
}

table {
  tr:focus {
    background-color: red !important;
  }
}

#status-table {
  .medimo-table {
    min-height: 0;

    .table {
      min-height: 0;

      .tbody {
        min-height: 0;
      }
    }
  }
}

#report-records {
  .report-record-row .medimo-td {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.medimo-th {
  .checkbox-align-middle {
    vertical-align: middle;
    display: inline-block;
  }
}
