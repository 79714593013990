
@import "/variables";
#admin-side-panel {
  .no-overflow {
    text-overflow: " (...)";
    overflow: hidden;
    white-space: nowrap;
    color: $medimo-75;
  }
}
