
svg.svg-inline--fa {
  color: #bababa;
  &.fa-plus, &.fa-xmark {
    cursor: pointer;
  }
  &.fa-arrow-turn-down-right {
    position:absolute;
    top: 6px;
    right: 3px;
  }
}
.protocol-item {
  height: 100%;
  padding: 0 0 10px 10px;
  .item-text {
    float: left;
    width: 195px;
    min-height: 10px;
    .options {
      width: 190px;
      padding-top: 5px;
      .button {
        color: #444;
        background-color: #F3F3F3;
        width: 100%;
        text-align: left;
        cursor: pointer;
        padding: 1px 1px 1px 10px;
        height: 25px;
        margin-top: 1px;
        margin-bottom: 1px;
        border: 1px solid #BABABA;
      }
    }
    .title {
      margin-bottom: 15px;
      padding-top: 5px;
      text-align: left;
      &.editMode {
        cursor:pointer;
      }
      .title-head {
        font-weight: bold;
      }
    }
  }
  .remove {
    width:14px;
    min-height: 50px;
    position: absolute;
    top: 3px;
    right: 3px;
  }
  .addBottom {
    position:absolute;
    bottom: 0;
    left: 95px;
  }
  .addRight {
    position:absolute;
    bottom: 30%;
    right: 3px;
  }
}
