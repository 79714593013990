.row.hover:hover {

  background-color: $primary;
  color: white;
  border-color: $primary;

  div {
    //background-color: $primary;
  }

  .badge-light {
    color: $primary;
  }

  .btn.btn-link {
    color: white;
    font-size: inherit;

    .fa {
      color: $bright-blue;
    }
  }
}

.row {
  &.danger {
    border-color: $danger;
  }
}