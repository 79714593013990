.schema-toggle:hover {
  cursor: pointer;
}

.badge-start, .badge-stop {
  width: 38px;
}

.dosage-instruction, .dispense-schema-settings, .dispense-row {
  .badge {
    /*border: 1px solid;*/
  }

  .badge-start {
    /*opacity: .90;*/
    color: $medimo-5;
    font-weight: 600;
    background-color: $green;
  }

  .badge-stop {
    /*opacity: .90;*/
    color: $medimo-5;
    font-weight: 600;
    background-color: $red;
  }

  .bijzonderheden {
    .badge {
      text-align: center;
      width: 30px;

      &:hover {
        color: white;
      }
    }
    .fa-icons {
      font-size: $font-size-base;
    }
    .medimo-icon {
      top: 1px;
      margin-right: 0;
      font-size: 0.846rem;
    }
  }
}

div.table .tbody > .tr.dosage-instruction:hover .bijzonderheden {
  text-decoration: none;
}

#status-table {
  .th {
    svg.svg-inline--fa.fa-capsule, svg.svg-inline--fa.fa-capsule-duo {
      margin-left: -4px;
      color: $medimo;
    }
  }
}

.edit-dosage-instruction-row {
  min-height: 36px;
}
