/**
  This ensures the definitions within this file are applied by IE11 only
  Found at: https://stackoverflow.com/questions/20541306/how-to-write-a-css-hack-for-ie-11
**/
_:-ms-fullscreen, :root {

  #login-form {
    //background-color: red!important;
    margin-top: 100px;
  }

  #nav-search {
    input#nav-search-query::placeholder {
      padding-left: 32px;
      margin-top: inherit;
    }
  }

  #breadcrumb-container.overview-page {
    //top: -7px !important;
  }
  #breadcrumb-container {
    left: 180px !important;
    top: 12px !important;
  }

  //#home-link-logo {
  //  display: block !important;
  //}

  #top-nav-dropdown > .dropdown-menu.show, #top-nav-dropdown > .dropdown-menu {
    transform: none !important;
    left: -165px !important;
    top: 50px !important;
    position: absolute !important;
  }

  .is-clickable, .clickable {
    cursor: pointer !important;
  }

  html > body.v2 .datatableswrapper {
    display: block;
  }

  .checkbox-container .checkmark::after {
    top: 2px;
    left: 5px;
  }

  form.medimo-form {
    .medimo-input {
      input {
        height: $input-height;
        line-height: ($input-height - 10);
      }
    }

    input.form-control {
      line-height: 16px;
    }

    .medimo-radio .medimo-icon {
      top: 7px;
    }

    .schema-form-row {
      .administration-columns {
        margin-left: $spacer;
        padding: inherit!important;
      }
    }

    .medimo-datetime-picker {
      .medimo-date-time-picker-column-1 {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }
    }
  }

  .medimo-datetime-picker .input-container {
    display: inherit;
  }

  .modal-footer {
    // Er kan helaas geen spacing tussen de knoppen ivm... IE11, maar zo is het wel netjes links uitgelijnd
    display: inline;
  }

  .date-time-picker .justify-content-center {
    justify-content: center;
    text-align:center;
  }

  .datetimepicker .datepicker {
    overflow: visible;
  }
  .modal-dialog-scrollable .modal-content, .modal-dialog-scrollable .modal-body{
    overflow: visible;
    .datetimepicker {
      position: absolute;
      .pickers-container {
        position: fixed;
      }
    }
  }

  /*
   |--------------------------------------------------------------------------
   | Breakpoints
   |--------------------------------------------------------------------------
   |
   |
   |
   |
   */
  @include media-breakpoint-down(md) {
    #breadcrumb-container {
      left: $spacer !important;
      //top: -15px !important;
    }
  }

  /*
   |--------------------------------------------------------------------------
   | V2 Overrides
   |--------------------------------------------------------------------------
   |
   |
   |
   |
   */
  body.v2 {

    .breadcrumbs ol.breadcrumb {
      position: relative;
      top: -7px;
    }

    #breadcrumb-container {
      //left: 180px !important;
      top: 12px !important;
    }

    #agents-queue-switch {
      .bootstrap-switch .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-on, .bootstrap-switch .bootstrap-switch-label {
        line-height: 0;
      }
    }

  }

}
