#mutation-view {
  .page-component {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  #mutation-status-overview.page-component, #patient-info-summary.page-component {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  #mutation-action-buttons.page-component, #mutation-status-overview.page-component {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &.authorise {
    #mutation-levering-form {
      min-height: 165px;
    }
  }

  &.examine {
    #mutation-levering-form {
      min-height: 114px;
    }
  }

  #mutation-info {
    min-height: 196px;

    //&.bundled-mutation-info {
    //  min-height: 0;
    //}
  }

  #mutation-levering-form {
    background-color: $medimo-5;

    .medimo-input {
      width: 50px;
    }
  }

  .handlerInfo {
    font-size: 12px;
    //margin-left:3px;
  }
}

#patient-mutations-data-table-tbody, #ward-mutations-data-table-tbody {
  height: 128px;
  overflow-y: scroll;
  //position: relative;

  .scroll-overlay {
    opacity: 0.25;

    $so-height: 20px;
    height: $so-height;
    width: 100%;
    position: absolute;
    top: 150px;
    left: 0;
    pointer-events: none;

    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(#5579A3, 0) 0%, rgba(#5579A3, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(#5579A3, 0) 0%, rgba(#5579A3, 1) 100%);
    background: linear-gradient(180deg, rgba(#5579A3, 0) 0%, rgba(#5579A3, 1) 100%);
  }
}

#glass-pane {
  background-color: rgba(255,255,255, 0.75);
  position:absolute;
  top:0;
  left:0;
  z-index: 10000;
  height: 100% !important;
  width: 100% !important;

  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}
