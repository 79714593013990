
$datePickerRowHeight: 24px;
$timePickerRowHeight: $datePickerRowHeight;
$monthContainerHeight: 158px;
$timepickerHeight: 250px;
$timepickerColumnHeight: (7 * $timePickerRowHeight + 1);
$datepickerControlsMargins: 0 10px;
$weekdaysMargin: 0px 10px 0px 10px;

$borderDays: 1px solid $medimo-25;
$borderDates: 1px solid $medimo-15;

.medimo-datetime-picker {
  .datepicker-controls {
    margin: $datepickerControlsMargins !important;
  }

  &.is-invalid {
    input.form-control.datetime-text-input   {
      border: 1px solid $danger;
    }
  }

  .week-days {
    margin: $weekdaysMargin !important;
    //border: $borderDays;
  }

  .time-picker-column-item.active:hover {
    .time-picker-column-item-text {
      color: white !important;
    }
  }


  /** Hide the Second Selector */
  .time-picker > .time-picker-column:first-of-type {
    display: inherit;
  }

  //.time-picker > .time-picker-column {
  //  display: none;
  //}

  .time-picker {
    width: 90px; /* Make the time picker slimmer */
    max-width: 90px;
    height: $timepickerHeight;

    .time-picker-button {
      height: 41px;
      color: $medimo;
    }
    .filler-row {
      height: $timePickerRowHeight;
    }
  }

  .datepicker-container {
    width: 310px;
  }

  .input-container {
    display: flex;
    width: 100%;
    //margin-bottom: 15px;
  }

  .icon {
    z-index: 10;
    padding: 8px;
    margin-left: -30px;
    background: transparent;
    color: $primary;
    text-align: center;
  }

  .datetime-text-input {
    padding: 7px 8px 7px 8px;
  }

  .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
  }

  input {
    border: 1px solid $medimo-10;

    &:focus {
      border: 1px solid $medimo-25;
    }
  }

  &.sm {
    .datetime-text-input {
      height: 24px;
    }
  }

  .shortcuts {
    .medimo-icon {
      top: 2px;
    }
  }


  .datepicker-container .datepicker-controls .datepicker-button svg {
    //height: 27px;
    //width: 27px;
    stroke-width: 12;
  }

  .pickers-container {

    border: 1px solid $medimo-25;
    //height: 290px;

    //.time-picker {
    //  height: 290px!important;
    //}

    .datepicker-container .datepicker-days .datepicker-day {
      text-align: right;
      height: $datePickerRowHeight;
      background-color: white;
      border-top: $borderDays;
      border-left: $borderDates;

      &.uneven {
        background-color: $medimo-5;
      }
      &.left-column {
        border-left: none;
      }
      &.first-row {
        border-top: none;
      }
    }

    .calendar .week-days .week-days-container {
      background-color: white; // $medimo-10
      color: $dark !important;
      font-size: inherit !important;
      font-weight: $font-weight-bold;
      border-left: $borderDays;
      //border-bottom: 2px solid $medimo-75;
      //border-top: $borderDays;
      &:first-of-type {
        border-left: none;
      }
    }

    .time-picker-column-item-text::after {
      content: ':00';
      position: relative;
      right: 0px;
    }

    .time-picker-column-minutes {
      display: none !important;
    }

  }

  .month-container {
    height: $monthContainerHeight !important;
    padding: 0px 10px 7px 10px;

    .datepicker-days {
      //border-bottom: $borderDays;
    }
  }

  /* Below here is from the package itself */
  .custom-button {
    padding: 0 20px;
    position: relative;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: $border-radius;
    height: $datePickerRowHeight;
    font-size: 13px;
    outline: none;
    cursor: pointer;
    -webkit-transition: none;
    color: #fff;
    font-weight: 400;
  }

  .custom-button:hover .custom-button-effect {
    background: $medimo !important;
  }

  .date-buttons.custom-button {
    .medimo-icon {
      top: 0;
    }
  }

  .custom-button-content {
    position: relative
  }

  .custom-button svg {
    position: relative;
    fill: #1e90ff
  }

  .custom-button .custom-button-effect, .custom-button svg {
    -webkit-transition: none;
    transition: none;
  }

  .custom-button .custom-button-effect {
    position: absolute;
    background: #1e90ff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: $datePickerRowHeight;
    border-radius: $border-radius;
    width: 100%;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  .custom-button.with-border {
    border: 1px solid #eaeaea
  }

  .custom-button.is-hover, .custom-button:hover {
    border: 1px solid transparent !important;

    .medimo-icon {
      color: white !important;
    }
  }

  .custom-button.is-hover .custom-button-effect, .custom-button:hover .custom-button-effect {
    -webkit-transform: scale(1);
    transform: scale(1);
    //opacity: .6
  }

  .custom-button.is-hover svg, .custom-button:hover svg {
    fill: #fff !important
  }

  .custom-button.is-hover .custom-button-content, .custom-button:hover .custom-button-content {
    color: #fff !important
  }

  .custom-button.is-selected {
    border: 1px solid transparent !important
  }

  .custom-button.is-selected .custom-button-effect {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  .custom-button.is-selected svg {
    fill: #fff !important
  }

  .custom-button.is-selected .custom-button-content {
    color: #fff !important
  }

  .custom-button.is-dark {
    background-color: #424242
  }

  .custom-button.is-dark.with-border {
    border-color: #757575
  }

  .custom-button.is-dark svg {
    fill: #fff !important
  }

  .custom-button.round {
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%
  }

  .custom-button.round .custom-button-effect {
    border-radius: 50%;
    height: 24px
  }

  .field {
    position: relative
  }

  .field.is-dark .field-label {
    color: hsla(0, 0%, 100%, .7)
  }

  .field.is-dark .field-input {
    background-color: #424242;
    border-color: hsla(0, 0%, 100%, .7);
    color: hsla(0, 0%, 100%, .7)
  }

  .field.is-dark.is-disabled .field-input, .field.is-dark.is-disabled .field-label {
    color: #000
  }

  .field-label {
    position: absolute;
    top: 5px;
    cursor: pointer;
    left: 13px;
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
    opacity: 0;
    -webkit-transition: none;
    transition: none;
    font-size: 11px;
    color: rgba(0, 0, 0, .54)
  }

  .field-input {
    cursor: pointer;
    background-color: #fff;
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
    position: relative;
    width: 100%;
    height: 42px;
    min-height: 42px;
    padding-left: 12px;
    padding-right: 44px;
    font-weight: 400;
    -webkit-appearance: none;
    outline: none;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: $border-radius;
    font-size: 14px;
    z-index: 0
  }

  .field-input.no-clear-button {
    padding: 0 12px
  }

  .field-clear-button {
    position: absolute;
    right: 12px
  }

  .field.has-error .field-input {
    border-color: #ff4500 !important
  }

  .field.has-error .field-label {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px
  }

  .field.has-error .field-input {
    padding-top: 14px
  }

  .field.has-value .field-label {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px
  }

  .field.has-value:not(.no-label) .field-input {
    padding-top: 14px
  }

  .field.is-focused .field-input {
    border-color: #1e90ff
  }

  .field.is-focused .field-label {
    color: #1e90ff
  }

  .field.is-disabled .field-input {
    border-color: #ccc;
    background: #f2f2f2
  }

  .field.is-disabled .field-input, .field.is-disabled .field-label {
    cursor: default
  }

  .field .text-danger {
    color: #ff4500 !important
  }

  .field.is-dark ::-webkit-input-placeholder {
    color: hsla(0, 0%, 100%, .7)
  }

  .field.is-dark :-moz-placeholder {
    color: hsla(0, 0%, 100%, .7);
    opacity: 1
  }

  .field.is-dark :-ms-input-placeholder {
    color: hsla(0, 0%, 100%, .7)
  }

  .field.is-dark ::-ms-input-placeholder {
    color: hsla(0, 0%, 100%, .7)
  }

  .field.is-dark ::placeholder {
    color: hsla(0, 0%, 100%, .7)
  }

  .field.is-dark.is-disabled ::-webkit-input-placeholder {
    color: #424242
  }

  .field.is-dark.is-disabled :-moz-placeholder {
    color: #424242;
    opacity: 1
  }

  .field.is-dark.is-disabled :-ms-input-placeholder {
    color: #424242
  }

  .field.is-dark.is-disabled ::-ms-input-placeholder {
    color: #424242
  }

  .field.is-dark.is-disabled ::placeholder {
    color: #424242
  }

  .field.sm .field-input {
    height: 36px;
    min-height: 36px;
    font-size: 12px
  }

  .field.sm .field-label {
    font-size: 10px
  }

  .field.sm.has-value:not(.no-label) .field-input {
    padding-top: 12px
  }

  .field.lg .field-input {
    height: 48px;
    min-height: 48px;
    font-size: 16px
  }

  .field.lg .field-label {
    font-size: 14px
  }

  .field.lg.has-value:not(.no-label) .field-input {
    padding-top: 16px
  }

  .shortcuts-container {
    width: 140px;
    max-width: 140px;
    min-width: 140px;
    padding: 10px 5px;
    border-right: 1px solid #eaeaea;
    overflow: auto
  }

  .shortcuts-container button.shortcut-button {
    margin-bottom: 10px;
    width: 100%
  }

  .shortcuts-container.is-dark {
    border-color: #757575
  }

  //@media screen and (max-width: 415px) {
  //  .shortcuts-container:not(.inline) {
  //    width: 100%;
  //    max-width: 100%;
  //    min-width: 100%;
  //    border-right: 0;
  //    border-bottom: 1px solid #eaeaea
  //  }
  //  .shortcuts-container.is-dark {
  //    border-color: #757575
  //  }
  //}

  .year-month-selector {
    position: absolute;
    background-color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #424242;
    padding: 10px
  }

  .year-month-selector.dark {
    color: #fff;
    background-color: #424242
  }

  .year-month-selector .month-button {
    text-transform: capitalize
  }

  .week-days {
    height: $datePickerRowHeight;
    text-transform: capitalize;
    border-bottom: 1px solid $medimo-75;
  }

  .week-days.is-dark .week-days-container {
    color: #a8a8a8 !important
  }

  //@media screen and (max-width: 415px) {
  //  :not(.inline) .datepicker-week {
  //    height: 21px !important
  //  }
  //}

  .datepicker-container {
    width: 260px;
    padding: 0 0;
    position: relative
  }

  .datepicker-container.range.has-shortcuts {
    width: 400px
  }

  .datepicker-container.p-0 {
    padding: 0
  }

  .datepicker-container .padding-button {
    padding: calc($spacer / 2);
  }

  .datepicker-container .calendar {
    position: relative
  }

  .datepicker-container .datepicker-controls {
    height: 40px
  }

  .datepicker-container .datepicker-controls .arrow-month {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px
  }

  .datepicker-container .datepicker-controls .datepicker-button {
    background: transparent;
    cursor: pointer;
    padding: 0 10px;
    border: none;
    outline: none
  }

  .datepicker-container .datepicker-controls .datepicker-button svg {
    height: 17px;
    width: 17px;
    fill: #2c3e50
  }

  .datepicker-container .datepicker-controls .datepicker-button.datepicker-prev {
    text-align: left !important;

    .medimo-icon {
      top: 0;
    }
  }

  .datepicker-container .datepicker-controls .datepicker-button.datepicker-next {
    text-align: right !important;

    .medimo-icon {
      top: 0;
    }
  }

  .datepicker-container .datepicker-controls .datepicker-container-label {
    text-transform: capitalize;
    font-size: 16px;
    position: relative;
    height: 46px;
    overflow: hidden
  }

  .datepicker-container .datepicker-controls .date-buttons {
    text-transform: capitalize;
    font-weight: 400
  }

  .datepicker-container .month-container {
    position: relative;
    overflow: hidden
  }

  .datepicker-container .datepicker-days {
    display: -webkit-box;
    display: flex;
    display: -ms-flexbox;
    overflow: hidden;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap
  }

  .datepicker-container .datepicker-days .datepicker-day {
    height: 41px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 14.28571%;
    position: relative;
    border: none;
    background: transparent;
    font-size: 13px;
    outline: none
  }

  .datepicker-container .datepicker-days .datepicker-day.enable {
    cursor: pointer
  }

  .datepicker-container .datepicker-days .datepicker-day-effect, .datepicker-container .datepicker-days .datepicker-day .datepicker-today {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: $datePickerRowHeight + 2;
    border-radius: 0;
    -webkit-transition: none;
    transition: none;
    border-top: $borderDays;
  }

  .datepicker-container .datepicker-days .datepicker-day .datepicker-day-effect {
    margin: auto;
    background: $medimo !important;
    border-radius: 0;
    //color: $medimo;
    font-weight: $font-weight-bold;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  // There are always exceptions - we don't want this background to happen to the datepicker that's today.
  .datepicker-today + .datepicker-day-effect {
    background: inherit !important;
  }

  .datepicker-today + .datepicker-day-effect + .datepicker-day-text {
    color: $medimo !important;
  }

  .datepicker-container .datepicker-days .datepicker-day:not(.disabled):hover .datepicker-day-text, .datepicker-container .datepicker-days .datepicker-day.other-month:not(.disabled):hover .datepicker-day-text {
    color: #fff !important;
  }

  .datepicker-container .datepicker-days .datepicker-day.disabled.other-month:hover .datepicker-day-text {
    color: #ccc
  }

  // This one controls the "regular" days in the current month.
  .datepicker-container .datepicker-days .datepicker-day:not(.other-month) .datepicker-day-text {
    //text-decoration: underline;
    //text-underline-position: under;
    color: $medimo;
    font-weight: 600;
  }

  .datepicker-container .datepicker-days .datepicker-day .datepicker-today {
    background-color: $medimo-10
  }

  .datepicker-container .datepicker-days .datepicker-day .datepicker-day-text {
    position: relative;
    color: $dark;
  }

  .datepicker-container .datepicker-days .datepicker-day .datepicker-day-text.today {
    color: $medimo;
    font-weight: bold;
  }

  .datepicker-container .datepicker-days .datepicker-day .datepicker-day-keyboard-selected {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 26px;
    width: 26px;
    opacity: .7;
    border-radius: 50%;
    -webkit-transition: none;
    transition: none;
    background-color: #afafaf;
  }

  .datepicker-container .datepicker-days .datepicker-day.other-month {
    .datepicker-day-text {
      color: $medimo;
      //opacity: 0.65;
      font-style: italic;
    }
  }


  .datepicker-container .datepicker-days .datepicker-day:hover .datepicker-day-effect {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  .datepicker-container .datepicker-days .datepicker-day.between .datepicker-day-text {
    color: #fff
  }

  .datepicker-container .datepicker-days .datepicker-day.between .datepicker-day-effect {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: .5;
    border-radius: 0;
    width: 100%
  }

  .datepicker-container .datepicker-days .datepicker-day.between.first .datepicker-day-effect {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .datepicker-container .datepicker-days .datepicker-day.between.last .datepicker-day-effect {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .datepicker-container .datepicker-days .datepicker-day.between .datepicker-day-keyboard-selected, .datepicker-container .datepicker-days .datepicker-day.between.first .datepicker-day-keyboard-selected, .datepicker-container .datepicker-days .datepicker-day.between.last .datepicker-day-keyboard-selected {
    background-color: rgba(0, 0, 0, .66)
  }

  .datepicker-container .datepicker-days .datepicker-day.selected .datepicker-day-text {
    color: #fff!important;
    font-weight: $font-weight-bold
  }

  .datepicker-container .datepicker-days .datepicker-day.selected .datepicker-day-effect {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  .datepicker-container .datepicker-days .datepicker-day.selected .datepicker-day-keyboard-selected {
    background-color: rgba(0, 0, 0, .66)
  }

  .datepicker-container .datepicker-days .datepicker-day.disabled .datepicker-day-text {
    color: #ccc
  }

  .datepicker-container .datepicker-days .datepicker-day.disabled.selected {
    color: #fff
  }

  .datepicker-container .datepicker-days .datepicker-day.disabled .datepicker-day-effect {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0
  }

  .datepicker-container.is-dark .datepicker-days .datepicker-day:not(.between):not(.selected) .datepicker-day-text {
    color: #fff
  }

  .datepicker-container.is-dark .datepicker-days .datepicker-day:not(.between):not(.selected).disabled .datepicker-day-text {
    color: #757575
  }

  .datepicker-container.is-dark .datepicker-label {
    color: #fff
  }

  .datepicker-container.is-dark .text-muted {
    color: #a8a8a8 !important
  }

  .datepicker-container.is-dark .datepicker-button svg {
    fill: #fff
  }

  .datepicker-container.is-dark .datepicker-today {
    background-color: #292929 !important
  }

  //@media screen and (max-width: 415px) {
  //  #DatePicker.datepicker-container {
  //    width: 100%;
  //    -ms-flex-direction: column;
  //    -webkit-box-orient: vertical;
  //    -webkit-box-direction: normal;
  //    flex-direction: column;
  //    -ms-flex-flow: column;
  //    flex-flow: column;
  //    -moz-flex-direction: column
  //  }
  //  #DatePicker.datepicker-container:not(.inline) .datepicker-controls {
  //    height: 36px !important
  //  }
  //  #DatePicker.datepicker-container.range.has-shortcuts {
  //    width: 100%
  //  }
  //}

  //.time-picker-column::-webkit-scrollbar {
  //  display: none
  //}

  .time-picker {
    width: 80px;
    max-width: 160px;
    position: relative;
    z-index: 1
  }

  .time-picker.inline {
    width: 100%;
    max-width: 100%
  }

  /** Dit waren 2 lijnen die aangaven waar je "scrollwise" was, maar dit mocht er uit */
  //.time-picker:after, .time-picker:before {
  //content: "";
  //top: 50%;
  //position: absolute;
  //margin: 0 auto;
  //margin-top: -14px;
  //height: 30px;
  //z-index: -1;
  //width: 85%;
  //left: 0;
  //right: 0;
  //-webkit-box-sizing: border-box;
  //box-sizing: border-box;
  //text-align: left;
  //border-top: 1px solid #ccc;
  //border-bottom: 1px solid #ccc
  //}

  .time-picker-column {
    position: relative;
    overflow-y: auto;
    height: $timepickerColumnHeight;
  }

  .time-picker-column-item {
    height: $timePickerRowHeight;
    min-height: $timePickerRowHeight;
    padding: 0;
    color: $medimo;
    cursor: pointer;
    position: relative;
    //border: none;
    background: transparent;
    font-size: 13px;
    width: 100%;
    outline: none;
    border-bottom: $borderDays;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .time-picker-column-item-effect {
    position: absolute;
    opacity: 0.10;
    background: $medimo!important;
    height: 24px;
    width: 100%;
    top:0;
    left:0;
    //top: 4px;
    //left: 15%;
    //-webkit-transition: none;
    //transition: all .45s cubic-bezier(.23, 1, .32, 1) 0ms;
    //-webkit-transform: scale(0);
    transform: scale(0);
    border-radius: 0;
  }

  .time-picker-column-item-effect:hover {
    //-webkit-transform: scale(1);
    //transform: scale(1)
  }

  .time-picker-column-item-text {
    position: relative
  }

  .time-picker-column-item:hover .time-picker-column-item-text {
    color: $dark;
    //font-weight: bold;
    -webkit-transition: none;
    transition: none;
  }

  .time-picker-column-item:hover .time-picker-column-item-effect {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  .time-picker-column-item.active {
    color: #fff;
    font-weight: $font-weight-bold
  }

  .time-picker-column-item.active .time-picker-column-item-effect {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  .time-picker-column-item.disabled .time-picker-column-item-text {
    color: #ccc
  }

  .time-picker-column-item.disabled .time-picker-column-item-text:hover {
    color: #ccc !important
  }

  .time-picker-column-item.disabled .time-picker-column-item-effect {
    -webkit-transform: scale(0) !important;
    transform: scale(0) !important;
    opacity: 0 !important
  }

  .time-picker-column-item.disabled.active .time-picker-column-item-effect {
    background-color: #eaeaea !important;
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
    opacity: 1 !important
  }

  .time-picker.with-border {
    border-left: 1px solid #eaeaea
  }

  .time-picker.with-border.is-dark {
    border-left: 1px solid #757575
  }

  .time-picker.is-dark .time-picker-column-item-text {
    color: #fff
  }

  //@media screen and (max-width: 415px) {
  //  .time-picker:not(.inline) {
  //    border: 0;
  //    border-top: 1px solid #eaeaea;
  //    width: 100%;
  //    max-width: 100%;
  //    height: unset !important;
  //    overflow: hidden
  //  }
  //  .time-picker:not(.inline).dark {
  //    border-top: 1px solid #757575
  //  }
  //  .timepicker-container.is-dark {
  //    border-color: #757575
  //  }
  //}

  .header-picker {
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    color: #fff;
    position: relative
  }

  .header-picker-year {
    opacity: .7;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 14px;
    position: relative;
    height: 14px
  }

  .header-picker-date, .header-picker-hour, .header-picker-minute, .header-picker-range, .header-picker-time {
    font-size: 18px;
    line-height: 18px;
    position: relative;
    height: 18px
  }

  .header-picker-date {
    text-transform: capitalize
  }

  .header-picker-hour.twelve {
    min-width: 74px
  }

  .header-picker .pl-10 {
    padding-left: 10px
  }

  .header-picker .time-number {
    width: 22px
  }

  .header-picker.is-dark {
    border: 0;
    color: #fff !important
  }

  .datepicker-buttons-container {
    padding: 5px;
    border-top: 1px solid #eaeaea;
    background-color: #fff;
    z-index: 1;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important
  }

  .datepicker-buttons-container .datepicker-button {
    padding: 0 20px;
    position: relative;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: $border-radius;
    height: $datePickerRowHeight;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    -webkit-transition: none;
    color: #fff;
    font-weight: 400
  }

  .datepicker-buttons-container .datepicker-button-content {
    position: relative
  }

  .datepicker-buttons-container .datepicker-button svg {
    position: relative;
    -webkit-transition: none;
    transition: none;
    fill: #00c853
  }

  .datepicker-buttons-container .datepicker-button .datepicker-button-effect {
    position: absolute;
    background: #00c853;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: $datePickerRowHeight;
    border-radius: $border-radius;
    width: 100%;
    //-webkit-transition: none;
    //transition: all .45s cubic-bezier(.23, 1, .32, 1) 0ms;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  .datepicker-buttons-container .datepicker-button:hover {
    border: 1px solid transparent
  }

  .datepicker-buttons-container .datepicker-button:hover .datepicker-button-effect {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  .datepicker-buttons-container .datepicker-button:hover svg {
    fill: #fff !important
  }

  .datepicker-buttons-container .datepicker-button:hover .datepicker-button-content {
    color: #fff !important
  }

  .datepicker-buttons-container .datepicker-button.now.right-margin {
    margin-right: 10px
  }

  .datepicker-buttons-container .datepicker-button.now .datepicker-button-content {
    color: #1e90ff
  }

  .datepicker-buttons-container .datepicker-button.now .datepicker-button-effect {
    background: #1e90ff
  }

  .datepicker-buttons-container .datepicker-button.validate {
    border: 1px solid #eaeaea
  }

  .datepicker-buttons-container.is-dark .datepicker-button, .datepicker-buttons-container.is-dark {
    background-color: #424242
  }

  .datepicker-buttons-container.is-dark .datepicker-button:not(.now), .datepicker-buttons-container.is-dark:not(.now) {
    border-color: #757575
  }

  .datepicker-buttons-container.is-dark .datepicker-button svg, .datepicker-buttons-container.is-dark svg {
    fill: #fff !important
  }

  .datetimepicker {
    position: absolute;
    z-index: 2020;
    width: 100%
  }

  .datetimepicker.visible {
    z-index: 999
  }

  .datetimepicker .datepicker {
    position: absolute;
    z-index: 2020;
    border-radius: $border-radius;
    overflow: hidden;
    background: #fff;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .3);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .3);
    max-width: 400px
  }

  .datetimepicker .datepicker .pickers-container {
    background: #fff;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .datetimepicker .datepicker.right {
    right: 0
  }

  .datetimepicker.is-dark .datepicker, .datetimepicker.is-dark .pickers-container {
    background: #424242;
    border: 0
  }

  .inline .datepicker, .inline.datetimepicker {
    position: relative
  }

  .inline .datepicker {
    margin-bottom: 0 !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    width: 100%;
    max-width: 100%;
    background-color: #fff
  }

  //@media screen and (max-width: 415px) {
  //  .pickers-container {
  //    -ms-flex-direction: column;
  //    -webkit-box-orient: vertical;
  //    -webkit-box-direction: normal;
  //    flex-direction: column;
  //    -ms-flex-flow: column;
  //    flex-flow: column;
  //    -moz-flex-direction: column
  //  }
  //  .datetimepicker:not(.inline) {
  //    margin: 0 !important;
  //    position: absolute;
  //    top: 0 !important;
  //    bottom: 0;
  //    right: 0;
  //    left: 0
  //  }
  //  .datetimepicker:not(.inline) .datepicker {
  //    border-radius: 0 !important;
  //    bottom: 0 !important;
  //    top: 0 !important;
  //    left: 0 !important;
  //    right: 0 !important;
  //    width: 100% !important;
  //    max-width: inherit !important;
  //    min-width: inherit !important;
  //    position: fixed;
  //    height: 100%;
  //    margin: 0 !important
  //  }
  //}

  .date-time-picker {
    -webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale; // guess they fixed this
    color: #2c3e50;
    min-width: 125px;

    &.only-date {
      min-width: 120px;
    }
  }

  /** When we use a custom <slot>, we don't want a minimum width */
  &.custom-element {
    display: inline-block;

    .date-time-picker {
      min-width: inherit;
    }
  }

  /** This attempts to keep the DTP onscreen when it's on the right **/
  @include media-breakpoint-down(sm) {
    .datetimepicker .datepicker {
      left: -26px;
    }
  }
}

//.date-time-picker, .date-time-picker input, .date-time-picker label, .date-time-picker p, .date-time-picker span {
//  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
//}

.date-time-picker .fluid {
  width: 100%
}

.date-time-picker .fill-height {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%
}

.date-time-picker .spacer {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important
}

.date-time-picker .align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.date-time-picker .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.date-time-picker .flex-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: start;
  -moz-box-align: start;
  -moz-box-pack: start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start
}

.date-time-picker .flex-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
  -moz-box-align: end;
  -moz-box-pack: end;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end
}

.date-time-picker .flex-direction-column {
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-flow: column;
  flex-flow: column;
  -moz-flex-direction: column
}

.date-time-picker .flex-direction-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
  -ms-flex-flow: column-reverse;
  flex-flow: column-reverse;
  -moz-flex-direction: column-reverse
}

.date-time-picker .flex-direction-row {
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -moz-flex-direction: row
}

.date-time-picker .justify-content-end, .date-time-picker .justify-content-right {
  justify-content: flex-end;
  -ms-flex-pack: end;
  -moz-box-align: end;
  -moz-box-pack: end;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -webkit-box-align: end
}

.date-time-picker .justify-content-center {
  justify-content: center;
  -ms-flex-pack: center;
  -moz-box-align: center;
  -moz-box-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -webkit-box-align: center
}

.date-time-picker .justify-content-between {
  justify-content: space-between
}

.date-time-picker .justify-content-around, .date-time-picker .justify-content-between {
  -ms-flex-pack: justify;
  -moz-box-align: stretch;
  -moz-box-pack: justify;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -webkit-box-align: stretch
}

.date-time-picker .justify-content-around {
  justify-content: space-around
}

.date-time-picker .flex-fill {
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto
}

.date-time-picker .flex-fixed {
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto
}

.date-time-picker .flex-1 {
  -webkit-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.date-time-picker .flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.date-time-picker .flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.date-time-picker .lm-fs-12 {
  font-size: 12px !important
}

.date-time-picker .lm-fs-14 {
  font-size: 14px !important
}

.date-time-picker .lm-fs-16 {
  font-size: 16px !important
}

.date-time-picker .lm-fs-18 {
  font-size: 18px !important
}

.date-time-picker .lm-fw-300 {
  font-weight: 300
}

.date-time-picker .lm-fw-400 {
  font-weight: 400
}

.date-time-picker .lm-fw-500 {
  font-weight: 400
}

.date-time-picker .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

//@media (min-width: 576px) {
//  .date-time-picker .container {
//    max-width: 540px
//  }
//}
//
//@media (min-width: 768px) {
//  .date-time-picker .container {
//    max-width: 720px
//  }
//}
//
//@media (min-width: 992px) {
//  .date-time-picker .container {
//    max-width: 960px
//  }
//}
//
//@media (min-width: 1200px) {
//  .date-time-picker .container {
//    max-width: 1140px
//  }
//}

.date-time-picker .lm-pr-1 {
  padding-right: .25rem !important
}

.date-time-picker .lm-pt-1 {
  padding-top: .25rem !important
}

.date-time-picker .lm-pb-1 {
  padding-bottom: .25rem !important
}

.date-time-picker .lm-pl-1, .date-time-picker .lm-px-1 {
  padding-left: .25rem !important
}

.date-time-picker .lm-px-1 {
  padding-right: .25rem !important
}

.date-time-picker .lm-py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important
}

.date-time-picker .lm-p-1 {
  padding: .25rem !important
}

.date-time-picker .lm-pr-2 {
  padding-right: .5rem !important
}

.date-time-picker .lm-pt-2 {
  padding-top: .5rem !important
}

.date-time-picker .lm-pb-2 {
  padding-bottom: .5rem !important
}

.date-time-picker .lm-pl-2, .date-time-picker .lm-px-2 {
  padding-left: .5rem !important
}

.date-time-picker .lm-px-2 {
  padding-right: .5rem !important
}

.date-time-picker .lm-py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important
}

.date-time-picker .lm-p-2 {
  padding: .5rem !important
}

.date-time-picker .lm-pr-3 {
  padding-right: 1rem !important
}

.date-time-picker .lm-pt-3 {
  padding-top: 1rem !important
}

.date-time-picker .lm-pb-3 {
  padding-bottom: 1rem !important
}

.date-time-picker .lm-pl-3, .date-time-picker .lm-px-3 {
  padding-left: 1rem !important
}

.date-time-picker .lm-px-3 {
  padding-right: 1rem !important
}

.date-time-picker .lm-py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.date-time-picker .lm-p-3 {
  padding: 1rem !important
}

.date-time-picker .lm-pr-4 {
  padding-right: 1.5rem !important
}

.date-time-picker .lm-pt-4 {
  padding-top: 1.5rem !important
}

.date-time-picker .lm-pb-4 {
  padding-bottom: 1.5rem !important
}

.date-time-picker .lm-pl-4, .date-time-picker .lm-px-4 {
  padding-left: 1.5rem !important
}

.date-time-picker .lm-px-4 {
  padding-right: 1.5rem !important
}

.date-time-picker .lm-py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important
}

.date-time-picker .lm-p-4 {
  padding: 1.5rem !important
}

.date-time-picker .lm-pr-5 {
  padding-right: 3rem !important
}

.date-time-picker .lm-pt-5 {
  padding-top: 3rem !important
}

.date-time-picker .lm-pb-5 {
  padding-bottom: 3rem !important
}

.date-time-picker .lm-pl-5, .date-time-picker .lm-px-5 {
  padding-left: 3rem !important
}

.date-time-picker .lm-px-5 {
  padding-right: 3rem !important
}

.date-time-picker .lm-py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important
}

.date-time-picker .lm-p-5 {
  padding: 3rem !important
}

.date-time-picker .lm-mr-1 {
  margin-right: .25rem !important
}

.date-time-picker .lm-mt-1 {
  margin-top: .25rem !important
}

.date-time-picker .lm-mb-1 {
  margin-bottom: .25rem !important
}

.date-time-picker .lm-ml-1, .date-time-picker .lm-mx-1 {
  margin-left: .25rem !important
}

.date-time-picker .lm-mx-1 {
  margin-right: .25rem !important
}

.date-time-picker .lm-my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important
}

.date-time-picker .lm-m-1 {
  margin: .25rem !important
}

.date-time-picker .lm-mr-2 {
  margin-right: .5rem !important
}

.date-time-picker .lm-mt-2 {
  margin-top: .5rem !important
}

.date-time-picker .lm-mb-2 {
  margin-bottom: .5rem !important
}

.date-time-picker .lm-ml-2, .date-time-picker .lm-mx-2 {
  margin-left: .5rem !important
}

.date-time-picker .lm-mx-2 {
  margin-right: .5rem !important
}

.date-time-picker .lm-my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important
}

.date-time-picker .lm-m-2 {
  margin: .5rem !important
}

.date-time-picker .lm-mr-3 {
  margin-right: 1rem !important
}

.date-time-picker .lm-mt-3 {
  margin-top: 1rem !important
}

.date-time-picker .lm-mb-3 {
  margin-bottom: 1rem !important
}

.date-time-picker .lm-ml-3 {
  margin-left: 1rem !important
}

.date-time-picker .lm-mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important
}

.date-time-picker .lm-my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important
}

.date-time-picker .lm-m-3 {
  margin: 1rem !important
}

.date-time-picker .lm-mr-4 {
  margin-right: 1.5rem !important
}

.date-time-picker .lm-mt-4 {
  margin-top: 1.5rem !important
}

.date-time-picker .lm-mb-4 {
  margin-bottom: 1.5rem !important
}

.date-time-picker .lm-ml-4, .date-time-picker .lm-mx-4 {
  margin-left: 1.5rem !important
}

.date-time-picker .lm-mx-4 {
  margin-right: 1.5rem !important
}

.date-time-picker .lm-my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important
}

.date-time-picker .lm-m-4 {
  margin: 1.5rem !important
}

.date-time-picker .lm-mr-5 {
  margin-right: 3rem !important
}

.date-time-picker .lm-mt-5 {
  margin-top: 3rem !important
}

.date-time-picker .lm-mb-5 {
  margin-bottom: 3rem !important
}

.date-time-picker .lm-ml-5 {
  margin-left: 3rem !important
}

.date-time-picker .lm-mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important
}

.date-time-picker .lm-my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important
}

.date-time-picker .lm-m-5 {
  margin: 3rem !important
}

.date-time-picker .lm-btn {
  padding: 10px 20px;
  margin-bottom: 20px;
  border: none;
  display: inline-block;
  border-radius: $border-radius;
  text-decoration: none;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  -webkit-transition: none;
  transition: none;
  background-color: #1e90ff;
  color: #fff;
  font-weight: 400
}

.date-time-picker .lm-btn:hover {
  background-color: #0077ea;
  -webkit-box-shadow: 0 0 8px 0 rgba(232, 237, 250, .6), 0 2px 4px 0 rgba(232, 237, 250, .5);
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, .6), 0 2px 4px 0 rgba(232, 237, 250, .5)
}

.date-time-picker .lm-btn.option {
  background-color: #424242
}

.date-time-picker .lm-btn.option:hover {
  background-color: #292929
}

.date-time-picker .lm-btn-success {
  background-color: #9acd32
}

.date-time-picker .lm-btn-success:hover {
  background-color: #7ba428
}

.date-time-picker .lm-btn-dark {
  background-color: #424242
}

.date-time-picker .lm-btn-dark:hover {
  background-color: #292929
}

.date-time-picker .lm-btn-danger {
  background-color: #ff4500
}

.date-time-picker .lm-btn-danger:hover {
  background-color: #cc3700
}

.date-time-picker .dark .lm-btn:hover {
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, .6), 0 2px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .6), 0 2px 4px 0 rgba(0, 0, 0, .5)
}

.date-time-picker .dark .lm-btn.option {
  background-color: #424242
}

.date-time-picker .dark .lm-btn.option:hover {
  background-color: #5c5c5c
}

.date-time-picker .slide-enter-active, .date-time-picker .slide-leave-active {
  opacity: 1;
  z-index: 998;
  -webkit-transition: none;
  transition: none;
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.date-time-picker .slide-enter, .date-time-picker .slide-leave-to {
  opacity: 0;
  z-index: 998;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px)
}

.date-time-picker .slideinvert-enter-active, .date-time-picker .slideinvert-leave-active {
  opacity: 1;
  z-index: 998;
  -webkit-transition: none;
  transition: none;
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.date-time-picker .slideinvert-enter, .date-time-picker .slideinvert-leave-to {
  opacity: 0;
  z-index: 998;
  -webkit-transform: translateY(40px);
  transform: translateY(40px)
}

.date-time-picker .slidenext-enter-active, .date-time-picker .slidenext-leave-active, .date-time-picker .slideprev-enter-active, .date-time-picker .slideprev-leave-active {
  position: absolute;
  -webkit-transition: none;
  transition: none;
}

.date-time-picker .slidenext-enter, .date-time-picker .slideprev-leave-to {
  -webkit-transform: translateX(100%);
  transform: translateX(100%)
}

.date-time-picker .slidenext-leave-to, .date-time-picker .slideprev-enter {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%)
}

.date-time-picker .slidevnext-enter-active, .date-time-picker .slidevnext-leave-active, .date-time-picker .slidevprev-enter-active, .date-time-picker .slidevprev-leave-active {
  position: absolute;
  -webkit-transition: none;
  transition: none;
}

.date-time-picker .slidevnext-enter, .date-time-picker .slidevprev-leave-to {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0
}

.date-time-picker .slidevnext-leave-to, .date-time-picker .slidevprev-enter {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0
}

//@media screen and (max-width: 415px) {
//  .date-time-picker .slide-enter-active, .date-time-picker .slide-leave-active, .date-time-picker .slideinvert-enter-active, .date-time-picker .slideinvert-leave-active {
//    -webkit-transition: none;
//    transition: none;
//  }
//}

.date-time-picker .lm-text-white {
  color: #fff
}

.date-time-picker .lm-dots-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

.date-time-picker .lm-text-muted {
  color: rgba(0, 0, 0, .54) !important
}

.date-time-picker .lm-text-strong {
  font-weight: 400
}

.date-time-picker .lm-text-center {
  text-align: center !important
}

.date-time-picker .lm-text-left {
  text-align: left !important
}

.date-time-picker .lm-text-right {
  text-align: right !important
}

.date-time-picker .lm-h-100 {
  height: 100% !important
}

.date-time-picker .lm-mh-100 {
  max-height: 100% !important
}

.date-time-picker .lm-w-100 {
  width: 100% !important
}

.date-time-picker .lm-mw-100 {
  max-width: 100% !important
}

.date-time-picker *, .date-time-picker :after, .date-time-picker :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.date-time-picker {
  //width: 100%;
  //margin: 0 auto;
  text-align: left;
  font-size: 14px;
  border-radius: $border-radius;
  position: relative
}

.date-time-picker .time-picker-overlay {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .4)
}

//@media screen and (max-width: 415px) {
//  .time-picker-overlay {
//    display: none
//  }
//  .date-time-picker:not(.inline) {
//    position: inherit !important
//  }
//}
